import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
const { FIELD_TYPES } = Form;

const fields = {
  icon: <i className="icon fa-thin fa-truck-plane" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    description: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'description', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'isReturnedMaterialInWorkflow',
      name: 'returnedMaterialInWorkflow',
      labelStringId: 'returnedMaterialInWorkflow',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'returnedMaterialInWorkflow',
      defaultValue: true,
      formatter: props => (
        <div
          data-testclass={'cell-column-isReturnedMaterialInWorkflow'}
          data-testid={`row-${props.row.crn}-column-isReturnedMaterialInWorkflow`}
        >
          {props.row.isReturnedMaterialInWorkflow ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'isWaterInWorkflow',
      name: 'waterInWorkflow',
      labelStringId: 'waterInWorkflow',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'waterInWorkflow',
      defaultValue: true,
      formatter: props => (
        <div
          data-testclass={'cell-column-isWaterInWorkflow'}
          data-testid={`row-${props.row.crn}-column-isWaterInWorkflow`}
        >
          {props.row.isWaterInWorkflow ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};

export default fields;
