import { Auth } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from './menu-config';

export const IFrameAuthenticated = ({ children }) => {
  const { entityRef } = useParams();

  return (
    <>
      <Auth.IFrameAuthenticated entityRef={entityRef} menuConfig={menuConfig} />
    </>
  );
};
