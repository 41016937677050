import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { Column, Row, Container } = Layout;
const { Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

export const TicketEvents = ({ busy, onSave }) => {
  const { Components } = Form.useFormContext();

  return (
    <Styled className={cn('general')}>
      <Column flex={1}>
        <Container flex={1} style={{ padding: '15px' }}>
          <Typography.H3>
            <Localization.Translate stringId="statusTimes" data-testid="statusTimes-title" />
          </Typography.H3>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="PRINTED" data-testid="PRINTED_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HidePrintedEvent />
            </Column>
            <Column flex={1}>
              <Components.IsPrintedEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="LOADING_STARTED" data-testid="LOADING_STARTED_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideLoadingStartedEvent />
            </Column>
            <Column flex={1}>
              <Components.IsLoadingStartedEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="FIRST_WCCONTACT" data-testid="FIRST_WCCONTACT_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideFirstWaterCementContact />
            </Column>
            <Column flex={1}>
              <div></div>
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="LOADING_COMPLETE" data-testid="LOADING_COMPLETE_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideLoadingCompleteEvent />
            </Column>
            <Column flex={1}>
              <Components.IsLoadingCompleteEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="TO_JOB" data-testid="TO_JOB_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideToJobEvent />
            </Column>
            <Column flex={1}>
              <Components.IsToJobEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="ARRIVE_JOB" data-testid="ARRIVE_JOB_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideArriveJobEvent />
            </Column>
            <Column flex={1}>
              <Components.IsArriveJobEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="UNLOADING" data-testid="UNLOADING_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideUnloadingEvent />
            </Column>
            <Column flex={1}>
              <Components.IsUnloadingEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="END_UNLOADING" data-testid="END_UNLOADING_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideEndUnloadingEvent />
            </Column>
            <Column flex={1}>
              <Components.IsEndUnloadingEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="LEAVE_JOB" data-testid="LEAVE_JOB_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideLeaveJobEvent />
            </Column>
            <Column flex={1}>
              <Components.IsLeaveJobEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="IN_YARD" data-testid="IN_YARD_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideInYardEvent />
            </Column>
            <Column flex={1}>
              <Components.IsInYardEditable />
            </Column>
          </Row>
        </Container>
        <Container flex={1} style={{ padding: '15px' }}>
          <Typography.H3>
            <Localization.Translate stringId="pumpStatusTimes" data-testid="pumpStatusTimes-title" />
          </Typography.H3>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="PUMPING_STARTED" data-testid="PUMPING_STARTED_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HidePumpingStartedEvent />
            </Column>
            <Column flex={1}>
              <Components.IsPumpingStartedEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="PUMPING_COMPLETE" data-testid="PUMPING_COMPLETE_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HidePumpingCompleteEvent />
            </Column>
            <Column flex={1}>
              <Components.IsPumpingCompleteEditable />
            </Column>
          </Row>
          <Row>
            <Column flex={1}>
              <Subtitle>
                <Translate stringId="READY_TO_PUMP" data-testid="READY_TO_PUMP_row" />
              </Subtitle>
            </Column>
            <Column flex={1}>
              <Components.HideReadyToPumpEvent />
            </Column>
            <Column flex={1}>
              <Components.IsReadyToPumpEditable />
            </Column>
          </Row>
        </Container>
        <div className="actions" style={{ padding: '15px' }}>
          <Core.Button loading={busy} type="primary" data-testid="save-button" stringId="save" onClick={onSave} />
        </div>
      </Column>
    </Styled>
  );
};
