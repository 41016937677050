import { Drawer, Form, usePostMessageContext } from 'connex-cds';
import React from 'react';
import { useDataStore } from './datastore/DataStore';
import { DriverAcceptance } from './DriverAcceptance';
import { useTicketContext } from './TicketContext';

const FormSubmit = ({ children }) => {
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { setOnSubmit } = Form.useFormContext();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();
  const { reset } = useTicketContext();
  const { setFieldValue } = Form.useFormContext();

  const saveTicket = React.useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      sendMessage(JSON.stringify({ type: 'submit' }));
      return response;
    },
    [submitTicket, sendMessage]
  );

  const handleCancel = React.useCallback(() => {
    setFieldValue('driverAcceptanceReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const onSubmit = React.useCallback(
    values => {
      if (values?.signature?.image || values?.driverAcceptanceReason || values?.customerRejectReason) {
        return saveTicket(values).then(response => {
          reset();
        });
      }

      openDrawer({
        titleStringId: 'driverAcceptance',
        component: <DriverAcceptance />,
        onClose: handleCancel,
      });
    },
    [handleCancel, openDrawer, reset, saveTicket]
  );

  React.useEffect(() => {
    setOnSubmit(onSubmit);
  }, [onSubmit, setOnSubmit]);

  return children;
};

export default FormSubmit;
