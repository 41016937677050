import { useQueryClient } from '@tanstack/react-query';
import { Core, Form, useEntity } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSetupByTypeId } from '../../../MasterDataProvider';
import { useSetupConfig } from '../../config';
import getFormProviderConfig from './getFormProviderConfig';
import { SetupView } from './SetupView';
import { useListValues } from './useListValues';

// TODO: Might need to move to SDK

const { FormProvider } = Form;

export const DynamicSetup = () => {
  const queryClient = useQueryClient();
  const { previousEntityRef } = useEntity();
  const { typeId } = useParams();

  const config = useSetupConfig();

  const setup = useSetupByTypeId();

  const formConfig = React.useMemo(() => {
    return getFormProviderConfig(typeId, config?.masterData?.[typeId]);
  }, [config?.masterData, typeId]);

  // React.useEffect(() => {
  //   // Upon a change in entityRef, remove the previous query which will trigger
  //   // an initial fetch (isLoading will be true) causing the FormProvider to reset.
  //   // Workaround for react-query bug where data is undefined and isLoading stuck on true
  //   if (previousEntityRef) {
  //     queryClient.removeQueries({ queryKey: ['setup', previousEntityRef, typeId] });
  //   }
  // }, [previousEntityRef, queryClient, typeId]);

  const initialValues = React.useMemo(
    () => ({
      [typeId]: setup.data,
    }),
    [setup.data, typeId]
  );

  const { configWithLists } = useListValues(formConfig);

  return (
    <Core.Spinner spin={setup.isLoading || !configWithLists}>
      <FormProvider config={configWithLists} initialValues={initialValues}>
        <SetupView typeId={typeId} config={config?.masterData?.[typeId]} />
      </FormProvider>
    </Core.Spinner>
  );
};
