import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import { TotalPumpedQuantity } from './TotalPumpedQuantity';
import { WaterAdd } from './WaterAdd';

const Styled = styled.div`
  &&& {
    margin-left: 5px;
    margin-right: -5px;
    & > * {
      &:not(:first-child) {
        margin-top: 3px;
      }
    }
  }
`;

export const ActivityMobile = () => {
  return (
    <Styled className={cn('activity-detail')}>
      <WaterAdd />
      <ProductsServices />
      <ReturnedMaterial />
      <TotalPumpedQuantity />
    </Styled>
  );
};
