import { Form, Localization } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-water';

export const WaterAdd = props => {
  const { values } = Form.useFormContext();

  const waterEvents = React.useMemo(() => {
    return values.waterAddedEvents.filter(waterAddedEvent => !waterAddedEvent.driverDidNotAdd);
  }, [values.waterAddedEvents]);

  if (!values?.waterAddedEvents?.length) {
    return null;
  }

  return (
    <Styled className={cn('status-times-mobile')}>
      {waterEvents?.map?.((waterEvent, index) => {
        return (
          <table>
            <tr className="header-row">
              <td className="header-cell" colSpan="2">
                <Localization.Translate stringId="waterAdded" data-testid="water-added-label" />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="quantity" data-testid="quantity-label" />
              </td>
              <td data-testid={`${testId}-row-${index}-activity-value`}>
                <Localization.Uom uom={waterEvent.quantity} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="concreteOnTruck" data-testid="water-added-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Uom uom={waterEvent.concreteOnTruck} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="reason" data-testid="reason-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-reason-value`}>
                {waterEvent?.reason?.description}
              </td>
            </tr>
          </table>
        );
      })}
    </Styled>
  );
};
