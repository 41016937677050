import cn from 'classnames';
import { Core, Drawer, Form, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCreateItem, useDataRetentionSetup, useUpdateItem } from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const intervals = [
  { id: '30days', value: 30 },
  { id: '60days', value: 60 },
  { id: '90days', value: 90 },
  { id: '1year', value: 365 },
  { id: '3years', value: 1095 },
];

export const categories = [{ id: 'orders' }];

export const DataRetentionEditor = () => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { translateObjects } = Localization.useTranslateObjects();

  const options = React.useMemo(() => {
    return translateObjects(intervals, {
      getPath: 'id',
      setPath: 'label',
    });
  }, [translateObjects]);

  const categoryOptions = React.useMemo(() => {
    return translateObjects(categories, {
      getPath: 'id',
      setPath: 'label',
    });
  }, [translateObjects]);

  const dataRetentionSetup = useDataRetentionSetup();

  const busy = React.useMemo(() => {
    return dataRetentionSetup.isLoading;
  }, [dataRetentionSetup.isLoading]);

  const create = useCreateItem();
  const update = useUpdateItem();

  const editSetupData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const saveSetupData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(() => {
    return saveSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveSetupData, values]);

  const handleEdit = React.useCallback(() => {
    return editSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editSetupData, values]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('data-retentions-editor')}>
        <Components.Type options={categoryOptions} />
        <Components.Value options={options} />
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
