import { css } from 'styled-components';

export default css`
  &:not(.tabbed) {
    padding-top: 24px;
    
  }
  .actions {
    display: flex;
    //gap: 15px;
    & > * {
      &:first-child {
        flex: 1;
      }
      &:last-child {
        display: flex;
        & > * {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
        //gap: 15px;
      }
    }
  }
`;
