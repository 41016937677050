import cn from 'classnames';
import { Core, Drawer, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useReasonCodeSetup } from '../../../../../MasterDataProvider';
import style from './style';

const { Button } = Core;
const { useDrawerContext } = Drawer;
const { Row } = Layout;

const Styled = styled.div`
  ${style}
`;

export const RejectTicket = ({ onCancel }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();

  const { closeDrawer } = useDrawerContext();

  const reasonCodeSetup = useReasonCodeSetup();

  const options = React.useMemo(() => {
    const data = reasonCodeSetup.data || [];
    return data.filter(d => d.type === 'REJECT');
  }, [reasonCodeSetup.data]);

  const handleOkClick = React.useCallback(() => {
    setFieldValue('customerStatus', 'REJECTED');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const disabled = React.useMemo(() => {
    return !values?.customerRejectReason;
  }, [values?.customerRejectReason]);

  return (
    <Styled className={cn('signature-comment')}>
      <Row stretchContent>
        <Components.CustomerRejectReason options={options} busy={reasonCodeSetup.isLoading} showSearch={false} />
      </Row>

      <Row style={{ justifyContent: 'right', padding: '20px' }}>
        <Button onClick={onCancel} stringId="cancel" data-testid="cancel-rejection-button" />
        <Button
          type="primary"
          danger
          disabled={disabled}
          onClick={handleOkClick}
          stringId="reject"
          data-testid="reject-submit-button"
        />
      </Row>
    </Styled>
  );
};
