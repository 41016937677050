import { css } from 'styled-components';

export default css`
  gap: 0;
  &.loading-point {
    margin-top: 20px;
  }
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .comment-section {
    margin-top: 10px;
  }
  .comment-date-time {
    font-weight: bold;
    display: flex;
    & > *:first-child {
      flex: 1;
    }
  }
  .actions {
    margin: auto;
    margin-top: 10px;
  }
  .comment-footer {
    display: flex;
    justify-content: flex-end;
    .comment-counter {
      flex: 1;
      text-align: right;
    }
  }
`;
