import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { View } from './View';

const { DrawerProvider } = Drawer;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .actions {
    margin-top: 20px;
    display: flex;
    //gap: 20px;
    justify-content: flex-end;
  }
`;

// TODO: probable duplication with ../add-products-services.  Refactor...

export const AddWater = () => {
  const {
    Components: { Water },
  } = Form.useFormContext();

  return (
    <Styled className={cn('water')}>
      <Water>
        <DrawerProvider>
          <View />
        </DrawerProvider>
      </Water>
    </Styled>
  );
};
