import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  margin-top: 15%;
`;
