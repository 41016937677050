import React, { useContext } from 'react';
import { isObject } from 'lodash';

import { useParams } from 'react-router-dom';
import { mobileTicket } from '../../../../../api';
import { cacheItem, clearCachedItem, getCachedItem } from '../../../../../util/cache';
import { usePostMessageListener } from './usePostMessageListener';

const DataStoreContext = React.createContext();

export const useDataStore = () => {
  const context = useContext(DataStoreContext);
  if (!context) {
    throw new Error(`useDataStore cannot be rendered outside of the DataStore context provider`);
  }
  return context;
};

// The assumption at this time is that it will be necessary for only one ticket to queue while waiting
// to come back online.

export const DataStore = ({ children }) => {
  const { entityRef } = useParams();
  const [isOnline, setIsOnline] = React.useState(true);

  const listener = React.useCallback(event => {
    let message;
    if (isObject(event?.data)) {
      message = event?.data;
    } else {
      try {
        message = JSON.parse(event?.data);
      } catch (e) {
        message = event?.data;
      }
    }

    if (message?.type === 'online') {
      setIsOnline(message?.value);
    }
  }, []);

  usePostMessageListener(listener);

  const processQueue = React.useCallback(() => {
    const ticket = getCachedItem('ticket');
    if (ticket && isOnline) {
      mobileTicket.updateTicket({ entityRef, ticket }).then(() => {
        clearCachedItem('ticket');
      });
    }
  }, [entityRef, isOnline]);

  React.useEffect(() => {
    if (isOnline) {
      processQueue();
    }
  }, [isOnline, processQueue]);

  const submitTicket = React.useCallback(
    ticket => {
      if (isOnline) {
        return mobileTicket.updateTicket({ entityRef, ticket });
      }
      cacheItem('ticket', ticket);
      return Promise.resolve({ queued: true });
    },
    [entityRef, isOnline]
  );

  return <DataStoreContext.Provider value={{ submitTicket, isOnline }}>{children}</DataStoreContext.Provider>;
};
