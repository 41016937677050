import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization, Form } from 'connex-cds';

export const Styled = styled.div`
  table {
    border: 1px solid #ccc;
    width: 100%;
    border-collapse: collapse;
    cursor: pointer;

    &.hasError {
      tr {
        td {
          background-color: #fafab0;

          .error {
            display: inline;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    tr {
      &.header-row {
        font-weight: bold;
        background-color: #eee;
      }
      td {
        border: none;

        .error {
          color: red;
          display: none;
        }

        &.header-cell {
          padding-left: 5px;
          width: 120px;
          &.full {
            width: 100%;
          }
        }

        &.data-cell {
          display: flex;

          & > * {
            &:first-child {
              flex: 1;
            }
          }

          .edit-trigger-wrapper {
          }
        }
      }
    }
  }
`;

/*
{
    "eventType": "FIRST_WCCONTACT",
    "eventDateTime": "2022-01-01T00:00:00Z",
    "eventTypeTranslated": "First Water Cement Contact"
}
 */

const testId = 'statusTimesGrid';

export const StatusTimesMobile = ({ ticketEvents, isRowEditable, handleRowEdit, handleRefreshClick, refreshing }) => {
  const { values, errors } = Form.useFormContext();

  const handleClick = React.useCallback(
    ticketEvent => {
      if (isRowEditable(ticketEvent)) {
        handleRowEdit(ticketEvent);
      }
    },
    [handleRowEdit, isRowEditable]
  );

  return (
    <Styled className={cn('status-times-mobile')}>
      {ticketEvents?.map?.((ticketEvent, idx) => {
        return (
          <table
            className={cn({ hasError: !!errors?.ticketEvents?.[ticketEvent.eventType] })}
            onClick={() => handleClick(ticketEvent)}
          >
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="status" data-testid="statusLabel" />
              </td>
              <td>
                {ticketEvent.eventTypeTranslated || ticketEvent.eventType}
                <span className="error">*</span>
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="time" data-testid="timeLabel" />
              </td>
              <td className="data-cell">
                <Localization.Time
                  date={values?.ticketEvents?.[ticketEvent.eventType]?.eventDateTime || ticketEvent.eventDateTime}
                />
                {isRowEditable(ticketEvent) && (
                  <div className="edit-trigger-wrapper">
                    <i className="fa-light fa-pen-to-square" data-testid={`${testId}-row-${idx}-editButton`} />
                  </div>
                )}
              </td>
            </tr>
          </table>
        );
      })}
    </Styled>
  );
};
