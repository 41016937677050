import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Localization, Typography, Form, Core } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';

const Styled = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .container {
    padding: 15px 25px;
    position: relative;
    overflow: visible;
    .has-error-icon {
      font-size: 1.5em;
      position: absolute;
      top: -5px;
      left: -5px;
      display: none;
    }
    &.hasError {
      border-color: var(--color-background-button-primary-dangerous);
      .has-error-icon {
        display: block;
        color: var(--color-background-button-primary-dangerous);
      }
    }

  table {
    width: 100%;
    margin-left: 60px;
    td {
      width: 33%;
    }
    th {
      text-align: left;
    }
  }

  .content {
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 0;
    }
    .left {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  .didNotAdd {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
`;

export const CustomListComponent = props => {
  const { data } = props;

  const {
    deleteItem,
    resetForm,
    parentFormContext: { errors },
  } = Form.useFormContext();

  const { ticket } = useTicketContext();

  const handleDeleteClick = React.useCallback(
    item => {
      deleteItem(item);
      resetForm({ reinitialize: true });
    },
    [deleteItem, resetForm]
  );

  const handleRowClick = React.useCallback(
    row => {
      if (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length !== 0 ||
        !row?.suggestedToDriver
      ) {
        props.onRowClick?.(row);
      }
    },
    [props, ticket?.ticketEvents]
  );

  const disabled = React.useCallback(
    item => {
      return (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length === 0 && item?.suggestedToDriver
      );
    },
    [ticket?.ticketEvents]
  );

  return (
    <Styled className={cn('custom-list-component')}>
      {data?.map?.((item, idx) => {
        return (
          <Layout.Container
            onClick={() => handleRowClick(item)}
            className={cn({ hasError: !!errors?.waterAddedEvents?.[idx] })}
          >
            <i className="has-error-icon fa-sharp fa-solid fa-circle-exclamation" />
            <div className="content">
              <div className="left">
                <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                  <div className="description-quantity">
                    <Typography.H2 data-testid="product-value">{item.waterProduct?.description}</Typography.H2>
                    {item?.driverDidNotAdd !== true && (
                      <Typography.H3 data-testid="quantity-cell">
                        <Localization.Uom uom={item.quantity} />
                      </Typography.H3>
                    )}
                  </div>
                  {item?.driverDidNotAdd !== true ? (
                    <div style={{ flex: '1' }}>
                      <table>
                        <thead>
                          <tr>
                            {item?.waterProduct?.mobileTicket?.isReasonFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="reason" data-testid="reason-header" />
                              </th>
                            )}
                            {item?.waterProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="onBoard" data-testid="concreteOnTruck-header" />
                              </th>
                            )}
                            {item?.waterProduct?.mobileTicket?.isTimeFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="time" data-testid="time-header" />
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {item?.waterProduct?.mobileTicket?.isReasonFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="reason-cell">{item?.reason?.description}</td>
                            )}
                            {item?.waterProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="concreteOnTruck-cell">
                                <Localization.Uom uom={item.concreteOnTruck} />
                              </td>
                            )}
                            {item?.waterProduct?.mobileTicket?.isTimeFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="time-cell">
                                <Localization.Time date={item.time} />
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className={'didNotAdd'}>
                      <Localization.Translate stringId="driverDidNotAdd" data-testid="driverDidNotAddText" />
                    </div>
                  )}
                </div>
              </div>
              <div className="right">
                <div className="deleteButton">
                  <Core.Button
                    onClick={e => {
                      handleDeleteClick(item);
                      e.stopPropagation();
                    }}
                    stringId="delete"
                    data-testid="delete-button"
                    type="primary"
                    danger
                    disabled={disabled(item)}
                  />
                </div>
              </div>
            </div>
          </Layout.Container>
        );
      })}
    </Styled>
  );
};
