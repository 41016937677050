import cn from 'classnames';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Drawer, Form } from 'connex-cds';
import { SetupList } from './SetupList';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const SetupView = ({ typeId, config }) => {
  const { Components, values } = Form.useFormContext();
  const { DrawerProvider } = Drawer;

  const SetupComponent = React.useMemo(() => {
    return Components[pascalCase(typeId)];
  }, [Components, typeId]);

  const Component = React.useMemo(() => {
    return config.noList ? config.customEditor : SetupList;
  }, [config.customEditor, config.noList]);

  return (
    <Styled className={cn('setup-view')}>
      <SetupComponent>
        <DrawerProvider>
          <Component typeId={typeId} config={config} values={values} />
        </DrawerProvider>
      </SetupComponent>
    </Styled>
  );
};
