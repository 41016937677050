import { debounce, isEqual } from 'lodash';
import { Form } from 'connex-cds';
import React from 'react';
import { useTicketContext } from './TicketContext';
const EDITABLE_EVENTS = [
  'PRINTED',
  'LOADING_STARTED',
  'LOADING_COMPLETE',
  'TO_JOB',
  'ARRIVE_JOB',
  'UNLOADING',
  'END_UNLOADING',
  'LEAVE_JOB',
  'IN_YARD',
  'PUMPING_STARTED',
  'PUMPING_COMPLETE',
  'READY_TO_PUMP',
];

export const RefreshMonitor = ({ children }) => {
  const { ticket } = useTicketContext();
  const { values, setFieldValue, touched } = Form.useFormContext();

  React.useEffect(() => {
    const ticketEvents = Object.keys(ticket.ticketEvents).reduce((acc, key) => {
      if (EDITABLE_EVENTS.includes(key)) {
        // acc[key] = ticket.ticketEvents[key] || {};
        acc[key] = {
          ...(ticket.ticketEvents[key] || {}),
          eventDateTime: values.ticketEvents?.[key]?.eventDateTime || ticket.ticketEvents[key].eventDateTime,
        };
      }

      // if (touched.ticketEvents?.[key]?.eventDateTime) {
      //   acc[key] = { ...acc[key], eventDateTime: values.ticketEvents[key].eventDateTime };
      // }else {
      //   console.log('values.ticketEvents', values.ticketEvents);
      //   console.log('ticket.ticketEvents', ticket.ticketEvents);
      // }

      return acc;
    }, {});

    const updateValues = debounce(val => {
      setFieldValue('ticketEvents', ticketEvents);
    }, 1000);

    if (!isEqual(ticketEvents, values.ticketEvents)) {
      updateValues();
    }
  }, [setFieldValue, ticket.ticketEvents, touched.ticketEvents, values.ticketEvents]);

  return children;
};
