import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,

  mobileTicket: 'Mobile Ticket',
  ...features,

  save: 'Save',

  username: 'Username',
  password: 'Password',
  login: 'Login',
  required: 'Required',

  light: 'Light',
  dark: 'Dark',

  rememberMe: 'Remember Me',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacy Policy',

  customer: 'Customer',
  project: 'Project',

  isBillable: 'Billable',
  dispatchDateTime: 'Date',
  orderId: 'Order',
  ticketId: 'Ticket',
  location: 'Location',
  vehicleTypeName: 'Vehicle',
  product: 'Product',
  salesTax: 'Sales Tax',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destination',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Time',

  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',

  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Column Filters',

  visible: 'Visible',
  hidden: 'Hidden',

  itemDetail: 'Item Detail',

  quantity: 'Quantity',

  unloadingMethod: 'Unloading Method',

  time: 'Time',
  eventStatus: 'Status',

  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
  one {1 Percent}
  other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
  one {1 Degree Celsius}
  other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
  one {1 Degree Fahrenheit}
  other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
  one {1 Barrel}
  other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
  one {1 Centilitre}
  other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
  one {1 Decilitre}
  other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  one {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,

  mixIdLabel: 'Mix ID/Description',
  orderedQtyLabel: 'Ordered Qty',
  loadedQtyLabel: 'Loaded Qty',

  addedByCustomer: 'Added By Customer',

  PRINTED: 'Ticketed',
  LOADING_STARTED: 'Start Loading',
  LOADING_COMPLETE: 'Finish Loading',
  TO_JOB: 'Left Plant',
  ARRIVE_JOB: 'Arrive Site',
  UNLOADING: 'Start Unload',
  END_UNLOADING: 'Finish Unload',
  LEAVE_JOB: 'Leave Job',
  IN_YARD: 'In Yard',
  PUMPING_STARTED: 'Start Pump',
  PUMPING_COMPLETE: 'Finish Pump',
  READY_TO_PUMP: 'Ready to Pump',
  FIRST_WCCONTACT: 'First Water Cement Contact',

  plantName: 'Plant Name',
  plantAddress: 'Plant Address',
  loadingScheduleStart: 'Scheduled Loading Time',

  deliveryAddress: 'Delivery Address',
  phoneNumber: 'Phone Number',
  unloadingScheduleStart: 'Planned Unloading Time (ETA)',
  agreedUnloading: 'Ordered Unloading Times',

  deliveryInformation: 'Delivery Information',
  next: 'Next',

  material: 'Material',
  loadingPoint: 'Loading Point',
  unloadingPoint: 'Unloading Point',

  comment: 'Comment',

  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  generalDelivery: 'General Delivery Information',
  materialInfo: 'Material Information',

  water: 'Water',
  materialService: 'Material / Service',
  returnedMaterial: 'Returned Material',

  waterAdded: 'Water Added',
  concreteOnTruck: 'Concrete on Truck',
  concreteOnTruckUom: 'Default Concrete on Truck UOM',
  reason: 'Reason',
  customerAddedWater: 'Customer Added Water',
  addWater: 'Add Water',
  addedAt: 'Added at',

  addMaterialService: 'Add Material/Service',
  customerAddedProduct: 'Customer Added Product',

  addReturnedMaterial: 'Add Returned Material',
  leftover: 'Leftover',

  submitAcceptance: 'Submit Acceptance',
  customerAcceptance: 'Acceptance',
  finalize: 'Finalize',

  materialSummary: 'Material Summary',
  mix: 'Mix',
  ordered: 'Ordered',
  loaded: 'Delivery Load',
  totalOrdered: 'Total Order Quantity',
  loadedOrderQuantity: 'Loaded Order Quantity',
  loadedOrder: 'Loaded Order',

  driverComment: 'Driver Comment',
  customerComment: 'Customer Comment',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  additionalInfo: 'Additional Info',
  onBoard: 'On Board',
  driver: 'Driver',
  commentInstruction: 'Comment Instruction',
  signatureInstruction: 'Customer Signature',
  customerSignature: 'Customer Signature',

  general: 'General',

  status: 'Status',
  updateStatusTime: 'Update Status Time',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  add: 'Add',
  ok: 'OK',
  lastRefresh: 'Last Refresh',
  refreshEnabled: 'Enable Refresh',

  id: 'ID',
  name: 'Name',
  invoiceable: 'Invoiceable',
  provideSignature: 'Provide Signature',
  ticket: 'Ticket',
  details: 'Details',
  qrCode: 'QR Code',

  driverAcceptanceReason: 'Reason',
  driverAcceptance: 'Driver Acceptance',

  'payment-method': 'Payment Methods',
  'add-payment-method': 'Add Payment Method',

  'reason-code': 'Reason Code',
  'add-reason-code': 'Add Reason Code',

  'qr-code': 'QR Code',
  'unloading-method': 'Unloading Methods',
  'add-unloading-method': 'Add Unloading Method',

  'driver-comment': 'Driver Comments',
  'add-driver-comment': 'Add Driver Comment',
  'driver-sellable': 'Driver Sellable',

  vehicle: 'Vehicles',
  'add-vehicle': 'Add Vehicle',

  'vehicle-type': 'Vehicle Types',
  vehicleTypes: 'Vehicle Types',
  vehicleType: 'Vehicle Type',
  'add-vehicle-type': 'Add Vehicle Type',
  countries: 'Countries',
  country: 'Country',

  type: 'Type',
  uom: 'UOM',
  mandatory: 'Mandatory',

  qrTemplate: 'QR Code Template',
  includeMixInfo: 'Include Mix Info in QR Code',
  includeTicketEvents: 'Include Ticket Events in QR Code',

  quantityField: 'Enable Quantity Field',
  concreteOnTruckField: 'Enable Concrete On Truck Field',
  timeField: 'Enable Time Field',
  reasonField: 'Enable Reason Field',

  driverInstructions: 'Driver Instructions',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'Value cannot exceed loaded quantity of {loadedQuantity}.',
  maxQuantity: 'Maximum Quantity',
  maxQuantityError: 'Quantity cannot exceed defined maximum quantity of {maxQty}',

  notAuthorized: 'Not Authorized',

  enterTruckNumber: 'Enter Truck Number',
  setTruckNumber: 'Set Truck Number',

  commentList: 'Comments List',
  list: 'List',

  gridPreferences: 'Grid Preferences',

  loadingUnloadingPoints: 'Loading/Unloading Points',

  activity: 'Activity',

  crn: 'CRN',
  update: 'Update',

  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',

  'submit-signature-button': 'Accept',
  'cancel-signature-button': 'Cancel',

  uomCode: 'Default UOM',
  'product-type': 'Product Types',
  'add-product-type': 'Add Product Type',
  'add-product': 'Add Product',
  'setup_driver-comment': 'Driver Comments',
  setup_product: 'Products',
  'setup_product-type': 'Product Types',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Reason Codes',
  'setup_unloading-method': 'Unloading Methods',
  'setup_entity-mt': 'Company',
  setup_vehicle: 'Vehicles',
  'setup_vehicle-type': 'Vehicle Types',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optional Fields',

  waterProduct: 'Water',
  waterProductType: 'Water Product Type',
  defaultWaterProduct: 'Default Water Product',

  invalidTemplate: 'Invalid Template',

  siteAddress: 'Site Address',
  siteContactName: 'Site Contact Name',
  siteContactPhoneNumber: 'Site Contact Phone Number',

  'clear-signature-button': 'Clear Signature',

  customerCommentEnabled: 'Enable Customer Comment',
  driverCommentEnabled: 'Enable Driver Comment',

  driverCommentMaxCharacters: 'Max Length',
  customerCommentMaxCharacters: 'Max Length',

  timeZone: 'Time Zone',

  driverDidNotAdd: 'Driver Did Not Add',
  maxLineItems: 'Max Number of Line Items',
  oneLineItem: 'Must have at least 1 line item',

  signatureSiteContact: 'Customer Name',
  ticketEvents: 'Ticket Events',
  hideEvent: 'Hide Event',
  editable: 'Editable',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Times',

  materialAndDeliveryInfo: 'Material and Delivery Information',

  customerRejection: 'Customer Rejection',
  'submit-rejection-button': 'Submit Rejection',
  accept: 'Accept',
  reject: 'Reject',

  signature: 'Signature',
  'no-signature': 'No Signature',

  unFinalizePassword: 'Un-Finalize Password',

  waterInWorkflow: 'Enable Water Step in Workflow',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WARNING: Time should not be before previous timestamp',
  timestampBeforeSequence: 'WARNING: Time should not be after next timestamp',

  noRestQuantity: 'No Rest Quantity',

  returnedMaterialMandatory: 'Must Interact With Returned Material Step',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  errors,
  themes,
};
