import { css } from 'styled-components';

export default css`
  padding-top: 24px;
  &.materialSummary {
    padding: 5px;
    display: flex;
    flex-direction: row;
    .left {
      flex: 1;
      padding-left: 10px;
    }
  }

  &.materialDetail {
    & > .container {
      padding: 10px;
      .subtitle {
        margin-bottom: 10px;
      }
    }
  }
`;
