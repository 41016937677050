import { http } from 'connex-cds';

export default {
  getMasterData: ({ entityRef, typeId }) => http.get({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}` }),
  getAllActiveMasterData: ({ entityRef }) => http.get({ apiName: 'mt', path: `/${entityRef}/master-data` }),
  getMasterDataItem: ({ entityRef, typeId, itemRef }) =>
    http.get({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}/${itemRef}` }),
  updateMasterData: ({ entityRef, typeId, item }) =>
    http.put({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}/${item?.crn}`, data: item }),
  createMasterData: ({ entityRef, typeId, item }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/master-data/${typeId}`, data: item }),
  createSetupData: ({ entityRef, itemType, item }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/${itemType}`, data: item }),
  updateSetupData: ({ entityRef, itemType, item }) =>
    http.put({ apiName: 'mt', path: `/${entityRef}/${itemType}/${item?.crn}`, data: item }),
};
