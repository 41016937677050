import cn from 'classnames';
import { Form, Localization, useQrCode } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../TicketContext';
import { useTemplate } from './qr-code/useTemplate';
import { usePreparedContext } from './usePreparedContext';

const Styled = styled.div`
  display: flex;
  justify-content: center;
`;

const QRCode = () => {
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();

  const preparedContext = usePreparedContext({ ticket, mobileTicket: values });

  const { isValid, compile } = useTemplate();

  const qrCodeData = React.useMemo(() => {
    if (isValid) {
      return compile(preparedContext);
    }
    return null;
  }, [compile, isValid, preparedContext]);

  const qrCode = useQrCode({ data: qrCodeData || ' ' });

  return (
    <Styled className={cn('qr-tab')}>
      {isValid ? qrCode : <Localization.Translate stringId="invalidTemplate" data-testid="invalid-template" />}
    </Styled>
  );
};

export default QRCode;
