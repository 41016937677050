import { css } from 'styled-components';

export default css`
  padding: 0 10px;
  gap: 0;
  
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  
  .table-section {
    margin: 0 10px;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
  }
`;
