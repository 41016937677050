import { Form, Localization } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-totalPumpedQuantity';

export const TotalPumpedQuantity = () => {
  const { values } = Form.useFormContext();

  if (!values?.totalPumpedQuantity) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>Total Pumped Quantity</td>
      <td data-testid={`${testId}-quantity-value`}>
        <Localization.Uom uom={values?.totalPumpedQuantity} />
      </td>
      <td></td>
      <td></td>
    </tr>
  );
};
