import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Detail } from './Detail';
import style from './style';
import { Typography, Layout, Localization, Drawer } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';

const Styled = styled(Layout.Container)`
  ${style}
`;
export const LoadingUnloadingPoints = ({ data }) => {
  const { openDrawer } = Drawer.useDrawerContext();
  const { ticket } = useTicketContext();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: ticket?.customData?.ticketType !== 'PMP' ? 'loadingUnloadingPoints' : 'unloadingPoint',
      component: <Detail data={data} />,
    });
  }, [data, openDrawer, ticket?.customData?.ticketType]);

  return (
    <Styled className={cn('loading-unloading-points')} onClick={handleClick}>
      <div className="left">
        <Typography.Subtitle>
          {ticket?.customData?.ticketType === 'PMP' ? (
            <Localization.Translate stringId="unloadingPoint" data-testid="loading-unloading-points-label" />
          ) : (
            <Localization.Translate stringId="loadingUnloadingPoints" data-testid="loading-unloading-points-label" />
          )}
        </Typography.Subtitle>
      </div>
      <div className="right">
        <Typography.Subtitle>
          <i className="fa-regular fa-angle-right" />
        </Typography.Subtitle>
      </div>
    </Styled>
  );
};
