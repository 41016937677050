import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { useParams } from 'react-router-dom';
import { masterData } from '../../../../../../../api';

const Styled = styled.div`
  ${style}
`;

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const GroupLayout = ({ config }) => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { entityRef, typeId } = useParams();

  const editMasterData = React.useCallback(
    item => {
      return masterData.updateMasterData({ entityRef, typeId, item });
    },
    [entityRef, typeId]
  );

  const saveMasterData = React.useCallback(
    item => {
      return masterData.createMasterData({ entityRef, typeId, item });
    },
    [entityRef, typeId]
  );

  const handleSave = React.useCallback(() => {
    return saveMasterData(values).then(() => {
      closeDrawer();
    });
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(() => {
    return editMasterData(values).then(() => {
      closeDrawer();
    });
  }, [closeDrawer, editMasterData, values]);

  return (
    <Styled className={cn('setup-editor')}>
      <Components.Groups>
        {config.map(group => {
          const TabComponent = Components[pascalCase(group.groupId)];

          return (
            <TabComponent data-testid={group.groupId}>
              {group.fields.map(field => {
                const FieldComponents = TabComponent.Components;
                const FieldComponent = FieldComponents[pascalCase(field.name || field.path)];
                return <FieldComponent />;
              })}
            </TabComponent>
          );
        })}
      </Components.Groups>
      <div className="actions">
        <div>
          <Components.CancelButton onCancel={closeDrawer} enabled />
          <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
        </div>
      </div>
    </Styled>
  );
};
