import React, { useContext } from 'react';

const AppData = React.createContext();

export const useAppData = () => {
  const context = useContext(AppData);
  if (!context) {
    throw new Error(`useAppData cannot be rendered outside of the AppData context provider`);
  }
  return context;
};

export const AppDataProvider = ({ children, ...props }) => {
  return <AppData.Provider value={props}>{children}</AppData.Provider>;
};
