import { Core, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { referencedKeys } from '../../../../delivery-information/qr-code/useTemplate';
import { usePreparedContext } from '../../../../delivery-information/usePreparedContext';
import { QrCodeTemplateErrors } from './QrCodeTemplateErrors';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const QrCodeTemplate = ({ busy, onSave }) => {
  const { Components, values } = Form.useFormContext();

  const emptyContext = usePreparedContext();

  const invalidReferences = React.useMemo(() => {
    const validKeys = Object.keys(emptyContext);
    return referencedKeys(values?.qrCodeTemplate).filter(f => !validKeys.includes(f));
  }, [emptyContext, values?.qrCodeTemplate]);

  return (
    <Styled className={cn('qr-code-template')}>
      <Components.QrCodeTemplate validationText={false} />
      <QrCodeTemplateErrors invalidReferences={invalidReferences} />
      <div className="actions">
        <Core.Button
          loading={busy}
          type="primary"
          data-testid="save-button"
          stringId="save"
          onClick={onSave}
          disabled={invalidReferences?.length}
        />
      </div>
    </Styled>
  );
};
