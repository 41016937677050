import { css } from 'styled-components';

export default css`
  flex: 1;
  display: flex;
  flex-direction: column;

  .customer-comment-config,
  .driver-comment-config {
    align-items: center;
  }

  .timeZone-form-field {
    width: 100%;
  }

  &.qr-code-template {
    & > * {
      &:first-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        textarea {
          flex: 1;
        }
      }
    }
    textarea {
      font-family: monospace;
      padding: 10px;
    }
  }
`;
