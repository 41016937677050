import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: 100%;

  @media (max-width: 950px) {
    height: inherit;
    flex: 1;
    padding-top: 0;
  }

  & > .container {
    padding-bottom: 20px;
  }

  .header {
    margin: 0 20px;
    @media (max-width: 950px) {
      margin: 0;
      position: sticky;
      top: 0;
    }

    display: flex;
    .left {
    }
    .steps {
      padding: 0 20px;
      @media (max-width: 950px) {
        padding: 0;
      }
      flex: 1;
    }
    .right {
    }
  }

  &.finalized {
    padding-top: 0;
    .step-content {
      padding-top: 0;
    }
  }

  .step-content {
    padding: 20px 20px 0 20px;
    @media (max-width: 950px) {
      padding: 0;
    }
    
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 951px) {
      overflow-y: auto;
    }
    .step-component {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
    .step-actions {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      //gap: 10px;
    }
  }

  &.form-section {
    &.add-products-form-section {
      & > .container {
        & > .row {
          gap: 0;
          & > * {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
        .actions {
          display: flex;
          margin-top: 0;
          .left {
            flex: 1;
          }
          .left,
          .right {
            & > * {
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    &.add-water-form-section {
      & > .container {
        & > .row {
          gap: 0;
          & > * {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        .actions {
          display: flex;
          margin-top: 0;
          .left {
            flex: 1;
          }
          .left,
          .right {
            & > * {
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .row-2 {
      display: flex;
      & > * {
        &:first-child {
          flex: 1;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
`;
