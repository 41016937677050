import cn from 'classnames';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { STEP_STATUS, WizardSteps, icons } from '../WizardSteps';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const MobileWizardSteps = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const step = React.useMemo(() => {
    return find(props.steps, { id: props.currentStep });
  }, [props.steps, props.currentStep]);

  return (
    <Styled className={cn('wizard')}>
      <div className="wizard-header">
        <div className="step-info">
          <div className="icon">
            {step.disabled && step.status !== STEP_STATUS.MANDATORY ? icons.disabled : icons[step.status]}
          </div>
          <div className="label">{step.title}</div>
        </div>
        <div className={cn('toggle-icon-wrapper', { isOpen })} onClick={handleToggle}>
          <i className="fa-regular fa-chevron-down toggle-icon" />
        </div>
      </div>
      <div className={cn('drop-down-wrapper', { isOpen })}>
        <WizardSteps {...props} />
      </div>
    </Styled>
  );
};
