const countryList = [
  {
    id: 'AF',
    label: 'Afghanistan',
  },
  {
    id: 'AL',
    label: 'Albania',
  },
  {
    id: 'DZ',
    label: 'Algeria',
  },
  {
    id: 'AS',
    label: 'American Samoa',
  },
  {
    id: 'AD',
    label: 'Andorra',
  },
  {
    id: 'AQ',
    label: 'Antarctica',
  },
  {
    id: 'AR',
    label: 'Argentina',
  },
  {
    id: 'AM',
    label: 'Armenia',
  },
  {
    id: 'AU',
    label: 'Australia',
  },
  {
    id: 'AT',
    label: 'Austria',
  },
  {
    id: 'AZ',
    label: 'Azerbaijan',
  },
  {
    id: 'BS',
    label: 'Bahamas',
  },
  {
    id: 'BD',
    label: 'Bangladesh',
  },
  {
    id: 'BB',
    label: 'Barbados',
  },
  {
    id: 'BY',
    label: 'Belarus',
  },
  {
    id: 'BE',
    label: 'Belgium',
  },
  {
    id: 'BZ',
    label: 'Belize',
  },
  {
    id: 'BM',
    label: 'Bermuda',
  },
  {
    id: 'BT',
    label: 'Bhutan',
  },
  {
    id: 'BO',
    label: 'Bolivia (Plurinational State of)',
  },
  {
    id: 'BR',
    label: 'Brazil',
  },
  {
    id: 'IO',
    label: 'British Indian Ocean Territory',
  },
  {
    id: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    id: 'BG',
    label: 'Bulgaria',
  },
  {
    id: 'CV',
    label: 'Cabo Verde',
  },
  {
    id: 'CA',
    label: 'Canada',
  },
  {
    id: 'TD',
    label: 'Chad',
  },
  {
    id: 'CL',
    label: 'Chile',
  },
  {
    id: 'CN',
    label: 'China',
  },
  {
    id: 'CX',
    label: 'Christmas Island',
  },
  {
    id: 'CC',
    label: 'Cocos (Keeling) Islands',
  },
  {
    id: 'CO',
    label: 'Colombia',
  },
  {
    id: 'CK',
    label: 'Cook Islands',
  },
  {
    id: 'CR',
    label: 'Costa Rica',
  },
  {
    id: 'CU',
    label: 'Cuba',
  },
  {
    id: 'CW',
    label: 'Curaçao',
  },
  {
    id: 'CY',
    label: 'Cyprus',
  },
  {
    id: 'CZ',
    label: 'Czechia',
  },
  {
    id: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    id: 'DK',
    label: 'Denmark',
  },
  {
    id: 'DO',
    label: 'Dominican Republic',
  },
  {
    id: 'EC',
    label: 'Ecuador',
  },
  {
    id: 'EG',
    label: 'Egypt',
  },
  {
    id: 'SV',
    label: 'El Salvador',
  },
  {
    id: 'EE',
    label: 'Estonia',
  },
  {
    id: 'FK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    id: 'FO',
    label: 'Faroe Islands',
  },
  {
    id: 'FJ',
    label: 'Fiji',
  },
  {
    id: 'FI',
    label: 'Finland',
  },
  {
    id: 'FR',
    label: 'France',
  },
  {
    id: 'GF',
    label: 'French Guiana',
  },
  {
    id: 'PF',
    label: 'French Polynesia',
  },
  {
    id: 'TF',
    label: 'French Southern Territories',
  },
  {
    id: 'GE',
    label: 'Georgia',
  },
  {
    id: 'DE',
    label: 'Germany',
  },
  {
    id: 'GH',
    label: 'Ghana',
  },
  {
    id: 'GI',
    label: 'Gibraltar',
  },
  {
    id: 'GR',
    label: 'Greece',
  },
  {
    id: 'GL',
    label: 'Greenland',
  },
  {
    id: 'GU',
    label: 'Guam',
  },
  {
    id: 'GT',
    label: 'Guatemala',
  },
  {
    id: 'GW',
    label: 'Guinea-Bissau',
  },
  {
    id: 'GY',
    label: 'Guyana',
  },
  {
    id: 'HT',
    label: 'Haiti',
  },
  {
    id: 'HN',
    label: 'Honduras',
  },
  {
    id: 'HK',
    label: 'Hong Kong',
  },
  {
    id: 'HU',
    label: 'Hungary',
  },
  {
    id: 'IS',
    label: 'Iceland',
  },
  {
    id: 'IN',
    label: 'India',
  },
  {
    id: 'ID',
    label: 'Indonesia',
  },
  {
    id: 'IR',
    label: 'Iran (Islamic Republic of)',
  },
  {
    id: 'IQ',
    label: 'Iraq',
  },
  {
    id: 'IE',
    label: 'Ireland',
  },
  {
    id: 'IL',
    label: 'Israel',
  },
  {
    id: 'IT',
    label: 'Italy',
  },
  {
    id: 'JM',
    label: 'Jamaica',
  },
  {
    id: 'JP',
    label: 'Japan',
  },
  {
    id: 'JO',
    label: 'Jordan',
  },
  {
    id: 'KZ',
    label: 'Kazakhstan',
  },
  {
    id: 'KE',
    label: 'Kenya',
  },
  {
    id: 'KI',
    label: 'Kiribati',
  },
  {
    id: 'KP',
    label: "Korea (Democratic People's Republic of)",
  },
  {
    id: 'KR',
    label: 'Korea, Republic of',
  },
  {
    id: 'KG',
    label: 'Kyrgyzstan',
  },
  {
    id: 'LV',
    label: 'Latvia',
  },
  {
    id: 'LB',
    label: 'Lebanon',
  },
  {
    id: 'LR',
    label: 'Liberia',
  },
  {
    id: 'LY',
    label: 'Libya',
  },
  {
    id: 'LT',
    label: 'Lithuania',
  },
  {
    id: 'LU',
    label: 'Luxembourg',
  },
  {
    id: 'MO',
    label: 'Macao',
  },
  {
    id: 'MY',
    label: 'Malaysia',
  },
  {
    id: 'MV',
    label: 'Maldives',
  },
  {
    id: 'MT',
    label: 'Malta',
  },
  {
    id: 'MH',
    label: 'Marshall Islands',
  },
  {
    id: 'MQ',
    label: 'Martinique',
  },
  {
    id: 'MU',
    label: 'Mauritius',
  },
  {
    id: 'MX',
    label: 'Mexico',
  },
  {
    id: 'FM',
    label: 'Micronesia (Federated States of)',
  },
  {
    id: 'MD',
    label: 'Moldova, Republic of',
  },
  {
    id: 'MC',
    label: 'Monaco',
  },
  {
    id: 'MN',
    label: 'Mongolia',
  },
  {
    id: 'MA',
    label: 'Morocco',
  },
  {
    id: 'MZ',
    label: 'Mozambique',
  },
  {
    id: 'MM',
    label: 'Myanmar',
  },
  {
    id: 'NA',
    label: 'Namibia',
  },
  {
    id: 'NR',
    label: 'Nauru',
  },
  {
    id: 'NP',
    label: 'Nepal',
  },
  {
    id: 'NL',
    label: 'Netherlands',
  },
  {
    id: 'NC',
    label: 'New Caledonia',
  },
  {
    id: 'NZ',
    label: 'New Zealand',
  },
  {
    id: 'NI',
    label: 'Nicaragua',
  },
  {
    id: 'NG',
    label: 'Nigeria',
  },
  {
    id: 'NU',
    label: 'Niue',
  },
  {
    id: 'NF',
    label: 'Norfolk Island',
  },
  {
    id: 'NO',
    label: 'Norway',
  },
  {
    id: 'PK',
    label: 'Pakistan',
  },
  {
    id: 'PW',
    label: 'Palau',
  },
  {
    id: 'PS',
    label: 'Palestine, State of',
  },
  {
    id: 'PA',
    label: 'Panama',
  },
  {
    id: 'PG',
    label: 'Papua New Guinea',
  },
  {
    id: 'PY',
    label: 'Paraguay',
  },
  {
    id: 'PE',
    label: 'Peru',
  },
  {
    id: 'PH',
    label: 'Philippines',
  },
  {
    id: 'PN',
    label: 'Pitcairn',
  },
  {
    id: 'PL',
    label: 'Poland',
  },
  {
    id: 'PT',
    label: 'Portugal',
  },
  {
    id: 'PR',
    label: 'Puerto Rico',
  },
  {
    id: 'QA',
    label: 'Qatar',
  },
  {
    id: 'RO',
    label: 'Romania',
  },
  {
    id: 'RU',
    label: 'Russian Federation',
  },
  {
    id: 'RE',
    label: 'Réunion',
  },
  {
    id: 'PM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    id: 'WS',
    label: 'Samoa',
  },
  {
    id: 'SA',
    label: 'Saudi Arabia',
  },
  {
    id: 'RS',
    label: 'Serbia',
  },
  {
    id: 'SC',
    label: 'Seychelles',
  },
  {
    id: 'SG',
    label: 'Singapore',
  },
  {
    id: 'SB',
    label: 'Solomon Islands',
  },
  {
    id: 'ZA',
    label: 'South Africa',
  },
  {
    id: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    id: 'SS',
    label: 'South Sudan',
  },
  {
    id: 'ES',
    label: 'Spain',
  },
  {
    id: 'LK',
    label: 'Sri Lanka',
  },
  {
    id: 'SD',
    label: 'Sudan',
  },
  {
    id: 'SR',
    label: 'Suriname',
  },
  {
    id: 'SE',
    label: 'Sweden',
  },
  {
    id: 'CH',
    label: 'Switzerland',
  },
  {
    id: 'SY',
    label: 'Syrian Arab Republic',
  },
  {
    id: 'TW',
    label: 'Taiwan, Province of China',
  },
  {
    id: 'TJ',
    label: 'Tajikistan',
  },
  {
    id: 'TH',
    label: 'Thailand',
  },
  {
    id: 'TL',
    label: 'Timor-Leste',
  },
  {
    id: 'TK',
    label: 'Tokelau',
  },
  {
    id: 'TO',
    label: 'Tonga',
  },
  {
    id: 'TT',
    label: 'Trinidad and Tobago',
  },
  {
    id: 'TN',
    label: 'Tunisia',
  },
  {
    id: 'TR',
    label: 'Turkey',
  },
  {
    id: 'TM',
    label: 'Turkmenistan',
  },
  {
    id: 'TC',
    label: 'Turks and Caicos Islands',
  },
  {
    id: 'TV',
    label: 'Tuvalu',
  },
  {
    id: 'UA',
    label: 'Ukraine',
  },
  {
    id: 'AE',
    label: 'United Arab Emirates',
  },
  {
    id: 'GB',
    label: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    id: 'UM',
    label: 'United States Minor Outlying Islands',
  },
  {
    id: 'US',
    label: 'United States of America',
  },
  {
    id: 'UY',
    label: 'Uruguay',
  },
  {
    id: 'UZ',
    label: 'Uzbekistan',
  },
  {
    id: 'VU',
    label: 'Vanuatu',
  },
  {
    id: 'VE',
    label: 'Venezuela (Bolivarian Republic of)',
  },
  {
    id: 'VN',
    label: 'Viet Nam',
  },
  {
    id: 'WF',
    label: 'Wallis and Futuna',
  },
  {
    id: 'EH',
    label: 'Western Sahara',
  },
];

module.exports = countryList;
