import cn from 'classnames';
import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;
const MaterialInfo = ({ data }) => {
  let mix = '';
  if (data?.lineItems && data?.lineItems.length > 0) {
    data?.lineItems.forEach((lineItem, key) => {
      if (lineItem.isPrimary) {
        mix = `${lineItem.item.id} / ${lineItem.item.description}`;
      }
    });
  }

  return (
    <Styled className={cn('test-form')}>
      {mix && (
        <div className="body">
          <Translate stringId="material" data-testid="materialLabel" /> : <span data-testid="material"> {mix} </span>
        </div>
      )}
      {data?.customData?.productDetails?.matSalesText && (
        <div className="body">
          <Translate stringId="salesText" data-testid="salesTextLabel" /> :{' '}
          <span data-testid="salesText"> {data?.customData?.productDetails?.matSalesText}</span>
        </div>
      )}
      {data?.customData?.productDetails?.strengthClass && (
        <div className="body">
          <Translate stringId="strengthClass" data-testid="strengthClassLabel" /> :{' '}
          <span data-testid="strengthClass"> {data?.customData?.productDetails?.strengthClass}</span>
        </div>
      )}
      {data?.customData?.productDetails?.environmentExposure && (
        <div className="body">
          <Translate stringId="environmentExposure" data-testid="environmentExposureLabel" /> :{' '}
          <span data-testid="environmentExposure"> {data?.customData?.productDetails?.environmentExposure}</span>
        </div>
      )}
      {data?.customData?.productDetails?.metExposureClass && (
        <div className="body">
          <Translate stringId="metExposureClasses" data-testid="metExposureClassesLabel" /> :{' '}
          <span data-testid="metExposureClasses">{data?.customData?.productDetails?.metExposureClass}</span>
        </div>
      )}
      {data?.customData?.productDetails?.dmax && (
        <div className="body">
          <Translate stringId="dmax" data-testid="dmaxLabel" /> :{' '}
          <span data-testid="dmax">{data?.customData?.productDetails?.dmax} </span>
        </div>
      )}
      {data?.customData?.productDetails?.certificationText && (
        <div className="body">
          <Translate stringId="standardCert" data-testid="standardCertLabel" /> :{' '}
          <span data-testid="standardCert"> {data?.customData?.productDetails?.certificationText}</span>
        </div>
      )}
      {data?.customData?.productDetails?.customerSlumpTarget && (
        <div className="body">
          <Translate stringId="workabilityTarget" data-testid="workabilityTargetLabel" /> :{' '}
          <span data-testid="workabilityTarget"> {data?.customData?.productDetails?.customerSlumpTarget}</span>
        </div>
      )}
      {data?.customData?.productDetails?.stoneQuality && (
        <div className="body">
          <Translate stringId="stoneQuality" data-testid="stoneQualityLabel" /> :{' '}
          <span data-testid="stoneQuality"> {data?.customData?.productDetails?.stoneQuality}</span>
        </div>
      )}
      {data?.customData?.productDetails?.sandQuality && (
        <div className="body">
          <Translate stringId="sandQuality" data-testid="sandQualityLabel" /> :{' '}
          <span data-testid="sandQuality"> {data?.customData?.productDetails?.sandQuality}</span>
        </div>
      )}
      {data?.customData?.productDetails?.targetWcRatioRecipe && (
        <div className="body">
          <Translate stringId="targetWC" data-testid="targetWCLabel" /> :{' '}
          <span data-testid="targetWC"> {data?.customData?.productDetails?.targetWcRatioRecipe}</span>
        </div>
      )}
      {data?.customData?.productDetails?.controlClass && (
        <div className="body">
          <Translate stringId="controlClass" data-testid="controlClassLabel" /> :{' '}
          <span data-testid="controlClass"> {data?.customData?.productDetails?.controlClass}</span>
        </div>
      )}
      {data?.customData?.productDetails?.chlorideClass && (
        <div className="body">
          <Translate stringId="chlorideClass" data-testid="chlorideClassLabel" /> :{' '}
          <span data-testid="chlorideClass"> {data?.customData?.productDetails?.chlorideClass}</span>
        </div>
      )}
      {data?.customData?.productDetails?.strengthClass1 && (
        <div className="body">
          <Translate stringId="typeAndStrength1" data-testid="typeAndStrength1Label" /> :{' '}
          <span data-testid="typeAndStrength1"> {data?.customData?.productDetails?.strengthClass1}</span>
        </div>
      )}
      {data?.customData?.productDetails?.strengthClass2 && (
        <div className="body">
          <Translate stringId="typeAndStrength2" data-testid="typeAndStrength2Label" /> :{' '}
          <span data-testid="typeAndStrength2"> {data?.customData?.productDetails?.strengthClass2}</span>
        </div>
      )}
      {data?.customData?.productDetails?.additionType && (
        <div className="body">
          <Translate stringId="typeOfAddition" data-testid="typeOfAdditionLabel" /> :{' '}
          <span data-testid="typeOfAddition"> {data?.customData?.productDetails?.additionType}</span>
        </div>
      )}
      {data?.customData?.productDetails?.admixtureType && (
        <div className="body">
          <Translate stringId="typeOfAdmixture" data-testid="typeOfAdmixtureLabel" /> :{' '}
          <span data-testid="typeOfAdmixture"> {data?.customData?.productDetails?.admixtureType}</span>
        </div>
      )}
      {data?.customData?.productDetails?.orderedTemperature && (
        <div className="body">
          <Translate stringId="orderedTemp" data-testid="orderedTempLabel" /> :{' '}
          <span data-testid="orderedTemp"> {data?.customData?.productDetails?.orderedTemperature}</span>
        </div>
      )}
      {data?.customData?.productDetails?.fiber1Type && (
        <div className="body">
          <Translate stringId="fibers1" data-testid="fibers1Label" /> :{' '}
          <span data-testid="fibers1"> {data?.customData?.productDetails?.fiber1Type}</span>
        </div>
      )}
      {data?.customData?.productDetails?.fiber2Type && (
        <div className="body">
          <Translate stringId="fibers2" data-testid="fibers2Label" /> :{' '}
          <span data-testid="fibers2"> {data?.customData?.productDetails?.fiber2Type}</span>
        </div>
      )}
      {data?.customData?.productDetails?.maturityColdHot && (
        <div className="body">
          <Translate stringId="calculatedMaturityTime" data-testid="calculatedMaturityTimeLabel" /> :{' '}
          <span data-testid="calculatedMaturityTime"> {data?.customData?.productDetails?.maturityColdHot}</span>
        </div>
      )}
    </Styled>
  );
};

export default MaterialInfo;
