import cn from 'classnames';
import { Form, Layout, Localization } from 'connex-cds';
import { find, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import style from '../style';

const { Column, Row, Container } = Layout;
const { Translate, Uom } = Localization;

const Styled = styled.div`
  ${style}
`;

export const TotalPumpedQuantity = () => {
  const { ticket } = useTicketContext();

  const {
    Components: { TotalPumpedQuantity },
    values,
    setFieldValue,
  } = Form.useFormContext();

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const mixInfo = React.useMemo(() => {
    return {
      mix: `${primaryLineItem?.item?.id} / ${primaryLineItem?.item?.description}`,
    };
  }, [primaryLineItem]);

  React.useEffect(() => {
    if (!values?.totalPumpedQuantity && primaryLineItem?.quantity) {
      setFieldValue('totalPumpedQuantity', {
        value: primaryLineItem?.quantity?.value,
        uom: primaryLineItem?.quantity?.uomCode,
      });
    }
  }, [
    primaryLineItem?.quantity,
    primaryLineItem?.quantity?.uomCode,
    primaryLineItem?.quantity?.value,
    setFieldValue,
    values?.totalPumpedQuantity,
  ]);

  return (
    <Styled className={cn('returned-materials')}>
      <Column>
        <Row>
          <Container flex={1}>
            <Column>
              <div className="body">
                <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Column>
            <TotalPumpedQuantity uomCode={primaryLineItem?.quantity?.uomCode} />
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
