import cn from 'classnames';
import { Core, Drawer, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { Button } = Core;
const { useDrawerContext } = Drawer;
const { Row } = Layout;

const Styled = styled.div`
  ${style}
`;

export const AcceptanceSignature = ({ ticketId, onCancel }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = useDrawerContext();

  const handleOkClick = React.useCallback(() => {
    setFieldValue('customerStatus', 'ACCEPTED');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const disabled = React.useMemo(() => {
    return !values?.signature?.image;
  }, [values?.signature]);

  return (
    <Styled className={cn('signature-comment')}>
      <Components.Signature edit ticketId={ticketId} />

      <Row style={{ float: 'right', padding: '20px 0' }}>
        <Core.Button onClick={onCancel} stringId="cancel-signature-button" data-testid="cancel-signature-button" />
        <Button
          type="primary"
          disabled={disabled}
          onClick={handleOkClick}
          stringId="submit-signature-button"
          data-testid="ok-button"
        />
      </Row>
    </Styled>
  );
};
