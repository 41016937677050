import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { masterData as masterDataApi } from '../../../../../../../api';
import { SetupEditor } from './SetupEditor';
import { SetupListHeader } from './SetupListHeader';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const SetupList = ({ typeId, config }) => {
  const { entityRef } = useParams();
  const { Components, resetMode } = Form.useFormContext();
  const queryClient = useQueryClient();

  const { openDrawer } = Drawer.useDrawerContext();

  const Editor = React.useMemo(() => {
    return config?.customEditor ? config.customEditor : SetupEditor;
  }, [config?.customEditor]);

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: typeId,
      component: <Editor config={config} />,
      onClose: resetMode,
    });
  }, [config, openDrawer, resetMode, typeId]);

  const navigate = useNavigate();

  const goBack = React.useCallback(() => {
    navigate('..');
  }, [navigate]);

  const queryObserver = React.useMemo(() => {
    return new QueryObserver(queryClient, {
      queryKey: ['setup', entityRef, typeId],
      queryFn: () => masterDataApi.getMasterData({ entityRef, typeId }),
    });
  }, [entityRef, queryClient, typeId]);

  return (
    <Styled className={cn('setup-list')}>
      <SetupListHeader onAdd={handleClick} typeId={typeId} onBack={goBack} />
      <div className="list-section">
        <Components.ListComponent
          onClick={handleClick}
          gridName={`connex-setup-${typeId}`}
          queryObserver={queryObserver}
        />
      </div>
    </Styled>
  );
};
