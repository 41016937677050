import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { ActivityDesktop } from './ActivityDesktop';
import { ActivityMobile } from './mobile/ActivityMobile';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import { TotalPumpedQuantity } from './TotalPumpedQuantity';
import style from './style';
import { Layout, Localization, Responsive, Typography } from 'connex-cds';
import { WaterAdd } from './WaterAdd';

const Styled = styled(Layout.Column)`
  ${style}
`;

export const Activity = () => {
  return (
    <Styled className={cn('activity')}>
      <Responsive>
        <ActivityMobile />
        <ActivityDesktop />
      </Responsive>
    </Styled>
  );
};
