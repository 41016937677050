import { find } from 'lodash';
import React from 'react';
import { FormattedTime } from 'react-intl';
import timeZones from '../../core/time-zone-picker/zone/zones';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const Time = ({ date, hideTimeZone = false, testId }) => {
  const { timeZone } = useLocalizationContext();

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  };

  const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);

  const tz = React.useMemo(() => {
    if (!dateTime) return null;
    const tzRecord = find(timeZones, { name: timeZone });
    return dateTime?.setLocale(tzRecord?.locale)?.toFormat?.('ZZZZ');
  }, [dateTime, timeZone]);

  if (!date) return null;

  return (
    <span data-testid={testId}>
      <FormattedTime value={dateTime.toJSDate()} {...options} />
      {hideTimeZone ? '' : ` (${tz})`}
    </span>
  );
};
