import { css } from 'styled-components';

export default css`
  padding-bottom: 24px;
  padding-top: 12px;
  .comment {
    textarea {
      width: 85%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .comment-footer {
    text-align: right;
  }

  //.signature-outer {
  //  width: 100%;
  //  position: relative;
  //  margin-left: auto;
  //  margin-right: auto;
  //  padding-top: 20%;
  //  .signature-inner {
  //    border: 1px solid #ccc;
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    right: 0;
  //    bottom: 0;
  //    background-color: #fff;
  //    overflow: hidden;
  //  }
  //}
`;
