import * as yup from 'yup';
import { DateTime } from 'luxon';

const events = [
  //'FIRST_WCCONTACT',
  'PRINTED',
  'LOADING_STARTED',
  'LOADING_COMPLETE',
  'TO_JOB',
  'ARRIVE_JOB',
  'READY_TO_PUMP',
  'UNLOADING',
  'PUMPING_STARTED',
  'END_UNLOADING',
  'PUMPING_COMPLETE',
  'LEAVE_JOB',
  'IN_YARD',
];

export default eventName => {
  const position = events.indexOf(eventName);
  let fn = yup.string();
  if (position > 0) {
    const previousEvents = events.reduce((acc, value, idx) => {
      if (idx < position) {
        acc.push(value);
      }
      return acc;
    }, []);
    if (previousEvents.length) {
      fn = fn.test('is-greater', 'timestampAfterSequence', function (value, context) {
        const previousTimestamps = previousEvents.map(e => context.from[1].value[e]?.eventDateTime).filter(x => !!x);
        if (context.from[1].value[eventName]?.eventDateTime && previousTimestamps.length) {
          return DateTime.fromISO(value) >= DateTime.fromISO(previousTimestamps.slice(-1));
        }
        return true;
      });
    }
  }
  if (position < events.length - 1) {
    const nextEvents = events.reduce((acc, value, idx) => {
      if (idx > position) {
        acc.push(value);
      }
      return acc;
    }, []);
    if (nextEvents.length) {
      fn = fn.test('is-before', 'timestampBeforeSequence', function (value, context) {
        const nextTimestamps = nextEvents.map(e => context.from[1].value[e]?.eventDateTime).filter(x => !!x);
        if (context.from[1].value[eventName]?.eventDateTime && nextTimestamps.length) {
          return DateTime.fromISO(value) <= DateTime.fromISO(nextTimestamps[0]);
        }
        return true;
      });
    }
  }
  return yup.object().shape({
    eventDateTime: fn,
  });
};
