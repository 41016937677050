import { Localization } from 'connex-cds';
import React from 'react';

export class GenericErrorBoundary extends React.Component {
  state = { hasError: false, visible: false };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  handleClick = () => {
    this.setState(s => ({ ...s, visible: !s.visible }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px', backgroundColor: 'white' }}>
          <h3 onClick={this.handleClick} style={{ cursor: 'pointer' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: '#FF0000', marginRight: '15px', fontSize: '2.2em' }}>
                <i className="fa-solid fa-triangle-exclamation"></i>
              </div>
              <div>An error has occurred</div>
            </div>
          </h3>

          <div
            style={{
              padding: '20px',
              backgroundColor: '#FFF0F0',
              border: '1px solid #FF2F2F',
              borderRadius: '7px',
              color: '#FF2F2F',
              display: this.state.visible ? 'block' : 'none',
            }}
          >
            <pre style={{ fontSize: '14px' }}>{this.state.error?.message}</pre>
          </div>
          <div>
            <span onClick={() => window?.location?.reload?.()}>
              Reload
            </span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
