export default `
General Delivery Info:
================================
Plant Name: {origin.description}
SAP Delivery Number: {sapDeliveryNumber}
Sales Order Number: {salesOrderNumber}
Date and time W/C contact: {wcContactDateTime}
Customer Id: {customerParty.id}
PO Number: {purchaseOrder}
Order placed by name: ??
Order placed by phone number: ??
Resource Id:  {vehicleId}
Loaded Qty: {loadedQuantity}
Total Loaded Qty: {totalLoadedQuantity}
Ordered Qty: {orderedQuantity} 
Load Spacing: {loadSpacing}
Delivery Flow: ??
Unloading Method: {unloadingMethod}
Total on site time/ordered unloading time: ??

Material Info
================================
material:  {productId} / {productDescription} 
Sales text (material ID): {matSalesText}
Strength Class: {strengthClass}
Environment Exposure: {environmentExposure}
Met Exposure Classes: {metExposureClass}
Dmax: {dmax}
Standard Cert: {standardCert}
Workability Target: {workabilityTarget}
Stone Quality: {stoneQuality}
Sand Quality: {sandQuality}
Target WC: {targetWc}
Control Class: {controlClass}
Chloride Class: {chlorideClass}
Type And Strength1: {strengthClass1}
Type And Strength2: {strengthClass2}
Type Of Addition: {additionType}
Type Of Admixture: {admixtureType}
Ordered Temp: {orderedTemp}
Type and content of fibers 1: {fiber1Type}
Type and content of fibers 2: {fiber2Type}
Calculated Maturity Time: {calculatedMaturityTime}

Ticket Events
================================
Printed: {printed}
To Job: {toJob}
Arrive Job: {arriveJob}
Unloading: {unloading}
End Unloading: {endUnloading} 
Leave Job: {leaveJob}
In Yard: {inYard}
`;
