import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../TicketContext';

const { ShortDateTime, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled(Layout.Container)``;

const UnloadingPoint = ({ className }) => {
  const { ticket } = useTicketContext();

  const deliveryAddress = React.useMemo(() => {
    const address = ticket?.destination?.address;
    return `${address?.address1 || ''} ${address?.city || ''}, ${address?.countrySubDivision || ''} ${
      address?.postalCode || ''
    }`;
  }, [ticket]);

  const siteContact = React.useMemo(() => {
    const contact = find(ticket?.destination?.contacts, { contactRole: 'general' });
    return { phone: contact?.phone || '', name: contact.name };
  }, [ticket?.destination?.contacts]);

  return (
    <Styled className={cn('unloading-point', className)}>
      <Subtitle>
        <Translate stringId="unloadingPoint" data-testid="unloadingPointLabel" />
      </Subtitle>
      <div>
        <Translate stringId="siteAddress" data-testid="siteAddressLabel" /> :{' '}
        <span data-testid="deliveryAddressValue">{deliveryAddress}</span>
      </div>
      <div>
        <Translate stringId="customer" data-testid="customerLabel" /> :{' '}
        <span data-testid="customerName">
          {ticket?.customerParty?.id}/{ticket?.customerParty?.name}
        </span>
      </div>

      <div>
        <Translate stringId="siteContactName" data-testid="siteContactNameLabel" /> :{' '}
        <span data-testid="siteContactName">{siteContact.name}</span>
      </div>
      <div>
        <Translate stringId="siteContactPhoneNumber" data-testid="customerPhoneNumberLabel" /> :{' '}
        <span data-testid="customerPhoneNumber">
          {' '}
          <a href={`tel:${siteContact.phone}`}>{siteContact.phone}</a>{' '}
        </span>
      </div>
      {ticket?.customData?.ticketType !== 'PMP' && (
        <div>
          <Translate stringId="unloadingScheduleStart" data-testid="unloadingScheduleStartLabel" />
          {': '}
          <span style={{ whiteSpace: 'nowrap' }} data-testid="unloadingScheduleStart">
            <ShortDateTime date={ticket?.customData?.schedule?.deliveryTimeFrom} />
          </span>
        </div>
      )}
      <div>
        <Translate stringId="agreedUnloading" data-testid="agreedUnloadingLabel" />
        {': '}
        <span style={{ whiteSpace: 'nowrap' }} data-testid="agreedUnloading">
          <ShortDateTime date={ticket?.customData?.schedule?.agreeWindowFrom} />
          {' - '}
          <ShortDateTime date={ticket?.customData?.schedule?.agreeWindowTo} />
        </span>
      </div>
    </Styled>
  );
};

export default UnloadingPoint;
