import { Responsive } from 'connex-cds';
import React from 'react';
import { DesktopWizardSteps } from './desktop/DesktopWizardSteps';
import { MobileWizardSteps } from './mobile/MobileWizardSteps';

export const ResponsiveWizardSteps = props => {
  return (
    <Responsive breakpoints={[950]}>
      <MobileWizardSteps {...props} />
      <DesktopWizardSteps {...props} />
    </Responsive>
  );
};
