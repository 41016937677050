import cn from 'classnames';
import { Core, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { SetupListHeader } from '../../master-data/dynamic-setup/SetupListHeader';
import { View } from './View';

const Styled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

// At the moment, we're attempting to treat company setup like any other setup item.
// A typical setup type will contain multiple items (e.g. products). In company setup there should always be only one item (i.e. an object containing company-setup data)
// Ensuring only one item is something we have to manage... such as eliminating the list/grid and jumping straight to the editor.

export const CompanyEditor = ({ typeId, config }) => {
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();

  const goBack = React.useCallback(() => {
    navigate('..');
  }, [navigate]);

  const companySetup = useCompanySetup();

  const initialValues = React.useMemo(() => {
    return companySetup?.data || {};
  }, [companySetup?.data]);

  return (
    <Styled className={cn('company-editor')}>
      <SetupListHeader typeId={typeId} onBack={goBack} />
      <Core.Spinner spin={companySetup.isLoading}>
        <Form.FormProvider config={config} initialValues={initialValues} disabled={busy}>
          <View busy={busy} setBusy={setBusy} />
        </Form.FormProvider>
      </Core.Spinner>
    </Styled>
  );
};
