import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import LoadingPoint from './LoadingPoint';
import style from './style';
import UnloadingPoint from './UnloadingPoint';
import { useTicketContext } from '../../../TicketContext';

const Styled = styled.div`
  ${style}
`;

export const Detail = ({ data }) => {
  const { ticket } = useTicketContext();

  return (
    <Styled className={cn('detail')}>
      {ticket?.customData?.ticketType !== 'PMP' && <LoadingPoint data={data} />}
      <UnloadingPoint data={data} />
    </Styled>
  );
};
