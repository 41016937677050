import cn from 'classnames';
import { Core, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCreateSetupItem, useUpdateSetupItem } from '../../../MasterDataProvider';
import { General } from './tabs/General';
import { TicketEvents } from './tabs/TicketEvents';
import { QrCodeTemplate } from './tabs/QrCodeTemplate';
import { Concrete } from './tabs/Concrete';

const Styled = styled(Layout.Column)`
  margin: 20px;
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .ant-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ant-tabs-content-holder {
      flex: 1;
      display: flex;
      flex-direction: column;
      .ant-tabs-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        .ant-tabs-tabpane {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;
export const View = ({ busy, setBusy }) => {
  const { values } = Form.useFormContext();
  const update = useUpdateSetupItem();
  const create = useCreateSetupItem();

  const handleSave = React.useCallback(() => create(values), [create, values]);
  const handleEdit = React.useCallback(() => update(values), [update, values]);

  const handleAddClick = React.useCallback(() => {
    setBusy(true);
    const promise = values?.crn ? handleEdit() : handleSave();
    promise.finally(() => {
      setBusy(false);
    });
  }, [handleEdit, handleSave, setBusy, values?.crn]);

  const tabConfig = React.useMemo(() => {
    const config = {
      tabs: [
        {
          stringId: 'general',
          testId: 'generalLabel',
          component: <General />,
        },
        {
          stringId: 'ticketEvents',
          testId: 'ticketEvents',
          component: <TicketEvents />,
        },
        {
          stringId: 'concrete',
          testId: 'concreteLabel',
          component: <Concrete />,
        },
      ],
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };

    if (values?.isQrCodeEnabled) {
      config.tabs.push({
        stringId: 'qrCode',
        testId: 'qrCodeLabel',
        component: <QrCodeTemplate />,
      });
    }
    return config;
  }, [values?.isQrCodeEnabled]);

  return (
    <Styled className={cn('view')}>
      <Core.Tabs config={tabConfig} busy={busy} onSave={handleAddClick} />
    </Styled>
  );
};
