import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
import { find } from 'lodash';

const { FIELD_TYPES } = Form;

const fields = (countries, setupVehicleTypes) => ({
  icon: <i className="icon fa-thin fa-clipboard-question" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    description: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  fields: [
    { path: 'crn', labelStringId: 'crn', testId: 'crn', dataType: FIELD_TYPES.string, hidden: true },
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'description', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'type',
      labelStringId: 'type',
      testId: 'type',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'SIGNATURE', label: 'Signature Not Provided' },
        { id: 'WATER', label: 'Water Added' },
        { id: 'RETURNED', label: 'Returned Material' },
        { id: 'ADDITIONAL_ITEM', label: 'Additional Item' },
        { id: 'REJECT', label: 'Customer Rejects Ticket' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
    },
    {
      path: 'countries',
      labelStringId: 'countries',
      testId: 'countries',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'label',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {countries?.map?.(country => (
              <option value={country.id} key={country.id}>
                {' '}
                {country.label}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-countries'} data-testid={`row-${props.row.crn}-column-countries`}>
          {props.row.countries?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'vehicleTypes',
      labelStringId: 'vehicleTypes',
      testId: 'vehicleTypes',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'description',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {setupVehicleTypes?.map?.(vehicleType => (
              <option value={vehicleType.id} key={vehicleType.id}>
                {' '}
                {vehicleType.description}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-vehicleTypes'} data-testid={`row-${props.row.crn}-column-vehicleTypes`}>
          {props.row.vehicleTypes
            ?.map?.(vehicleType => find(setupVehicleTypes, { id: vehicleType })?.description)
            ?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
});

export default fields;
