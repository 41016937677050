import cn from 'classnames';
import { Form, Core, Drawer } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../../TicketContext';
import { FLOW } from '../Acceptance';
import style from './style';
import { AcceptanceSignature } from './accept/AcceptanceSignature';
import { RejectTicket } from './reject/RejectTicket';

const Styled = styled.div`
  ${style}
`;
export const FinalizeActions = ({ ticketId, flow }) => {
  const { setFieldValue, Components, errors } = Form.useFormContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { finalized, setFinalized } = useTicketContext();

  const handleAcceptCancel = React.useCallback(() => {
    setFieldValue('signature', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleRejectCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleAcceptClick = React.useCallback(() => {
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId: 'customerSignature',
      component: <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />,
      width: '100%',
    });
  }, [handleAcceptCancel, openDrawer, ticketId]);

  const handleRejectClick = React.useCallback(() => {
    openDrawer({
      onClose: handleRejectCancel,
      titleStringId: 'customerRejection',
      component: <RejectTicket onCancel={handleRejectCancel} />,
      width: '700px',
    });
  }, [handleRejectCancel, openDrawer]);

  const handleClick = React.useCallback(() => {
    setFinalized(true);
    setFieldValue('finalized', true);
  }, [setFieldValue, setFinalized]);

  return finalized ? (
    <Styled className={cn('finalize-actions accept-reject')}>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button stringId="accept" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        ) : (
          <Core.Button stringId="signature" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        )}
      </div>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            danger
            stringId="reject"
            onClick={handleRejectClick}
            type="primary"
            data-testid="reject-button"
          />
        ) : (
          <Components.SubmitButton
            stringId="no-signature"
            data-testid="no-signature-button"
            type="primary"
            danger
            disabledWhenNotDirty={false}
            disabled={!isEmpty(omit(errors, 'ticketEvents'))}
          />
        )}
      </div>
    </Styled>
  ) : (
    <Styled className={cn('finalize-actions finalize')}>
      <div>
        <Core.Button stringId="finalize" onClick={handleClick} type="primary" data-testid="finalize-button" />
      </div>
    </Styled>
  );
};
