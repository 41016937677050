import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { DataRetentionEditor } from '../custom-editors/data-retentions-editor/DataRetentionEditor';
import { find } from 'lodash';
const { FIELD_TYPES } = Form;

const fields = (intervals, categories) => ({
  icon: <i className="icon fa-thin fa-database" />,
  validationSchema: Yup.object().shape({
    type: Yup.string().required('required'),
    value: Yup.string().required('required'),
  }),
  customEditor: DataRetentionEditor,
  fields: [
    {
      path: 'type',
      labelStringId: 'category',
      testId: 'category',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'label',
      formatter: props => (
        <div data-testclass={'cell-column-type'} data-testid={`row-${props.row.crn}-column-type`}>
          {find(categories, { id: props.row.type })?.label}
        </div>
      ),
    },
    {
      path: 'value',
      labelStringId: 'interval',
      testId: 'interval',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'value',
      listDisplayPath: 'label',
      defaultValue: 1095,
      formatter: props => (
        <div data-testclass={'cell-column-type'} data-testid={`row-${props.row.crn}-column-type`}>
          {find(intervals, { value: props.row.value })?.label}
        </div>
      ),
    },
  ],
});

export default fields;
