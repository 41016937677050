import cn from 'classnames';
import { Core, Drawer, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import messages from '../../../../../../../i18n';
import { useTicketContext } from '../../../TicketContext';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import style from './style';

const { Column, Row } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const FLOW = {
  ACCEPT_REJECT: 'accept-reject',
  SIGNATURE_NO_SIGNATURE: 'signature-no-signature',
};

export const Acceptance = ({ handleBackClick }) => {
  const { userLocale, timeZone } = Localization.useLocalizationContext();
  const { finalized } = useTicketContext();
  const { values } = Form.useFormContext();

  // TODO: This will come from company config.
  const flow = FLOW.SIGNATURE_NO_SIGNATURE;

  const signed = React.useMemo(() => {
    return !!values?.signature?.image;
  }, [values?.signature?.image]);

  return (
    <Localization.Localization messages={messages} initialLocale={userLocale} initialTimeZone={timeZone}>
      <Drawer.DrawerProvider>
        <Styled className={cn('acceptance')}>
          <Column>
            {finalized && (
              <div className="header">
                {signed ? null : (
                  <Core.Button
                    size="small"
                    type="primary"
                    stringId="back"
                    data-testid="back-button"
                    onClick={handleBackClick}
                  />
                )}
                <Typography.H3>
                  <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
                </Typography.H3>
                <Header flow={flow} />
              </div>
            )}
            <Row flex={1}>
              <Column flex={1} style={{ height: '420px' }}>
                <LogisticalInfo ticket={values} />
              </Column>
              <Column flex={2}>
                <Row flex={1}>
                  <Activity />
                </Row>
                <Row flex={1}>
                  <Comments />
                </Row>
                <Row flex={1}>
                  <FinalizeSection ticketId={values.id} flow={flow} />
                </Row>
              </Column>
            </Row>
          </Column>
        </Styled>
      </Drawer.DrawerProvider>
    </Localization.Localization>
  );
};
