import cn from 'classnames';
import { Form, Layout, Localization } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useReasonCodeSetup, useVehicleSetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import style from '../style';

const { Column, Row, Container } = Layout;
const { Translate, Uom } = Localization;

const Styled = styled.div`
  ${style}
`;

export const ReturnedMaterials = () => {
  const { ticket } = useTicketContext();

  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, DriverDidNotAdd },
    values,
    setFieldValue,
  } = Form.useFormContext();

  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const mixInfo = React.useMemo(() => {
    return {
      mix: `${primaryLineItem?.item?.id} / ${primaryLineItem?.item?.description}`,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [primaryLineItem]);

  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const reasonCodeSetup = useReasonCodeSetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [vehicleSetup.data, ticket?.vehicleId]);

  const options = React.useMemo(
    () =>
      reasonCodeSetup.data
        ?.filter?.(d => d.type === 'RETURNED')
        ?.filter?.(reason => reason.status === 'ACTIVE')
        ?.filter?.(reason =>
          companySetup?.data?.isMulticountry === true
            ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
            : reason
        )
        ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length) || [],
    [companySetup?.data?.isMulticountry, reasonCodeSetup.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  React.useEffect(() => {
    const defaultConcreteOnTruckUomId = companySetup.data?.concreteOnTruckUom;
    setConcreteOnTruckUom(defaultConcreteOnTruckUomId);
  }, [companySetup.data]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      setFieldValue('returnConcreteOnTruck', undefined);
      setFieldValue('returnReason', undefined);
    }
  }, [setFieldValue, values?.driverDidNotAdd]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);

  return (
    <Styled className={cn('returned-materials')}>
      <Column>
        <Row>
          <Container flex={1}>
            <Column>
              <div className="body">
                <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
              </div>
              <div className="body">
                <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
                <span data-testid="loadedQtyValue"> {mixInfo.loadedQty} </span>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Column>
            <ReturnConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
          </Column>
        </Row>
        <Row>
          <Column>
            <ReturnReason options={options} busy={reasonCodeSetup.isLoading} showSearch={false} disabled={disabled} />
          </Column>
        </Row>
        <Row>
          <Column>
            <DriverDidNotAdd />
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
