import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { View } from './View';

const { DrawerProvider } = Drawer;

const Styled = styled.div`
  .actions {
    margin-top: 20px;
    display: flex;
    //gap: 20px;
    justify-content: flex-end;
  }
`;

export const AddProducts = props => {
  const {
    Components: { MaterialService },
  } = Form.useFormContext();

  return (
    <Styled className={cn('add-products')}>
      <MaterialService>
        <DrawerProvider>
          <View />
        </DrawerProvider>
      </MaterialService>
    </Styled>
  );
};
