import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--color-page-background);
    overflow: auto;
    height: inherit;

    .step-content {
      overflow-y: unset;
      .step-component {
        .content-row {
          overflow: inherit;
        }
      }
    }

    .wizard-header {
      height: 35px;
      background-color: #eaeaea;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-bottom: 1px solid var(--color-border-container);
      .step-info {
        flex: 1;
        display: flex;
        font-size: 1.1em;
        align-items: center;
        .icon {
          font-size: 1.3em;
          margin-right: 10px;
        }
      }
      .toggle-icon-wrapper {
        .toggle-icon {
          transition: all 0.3s;
          cursor: pointer;
        }
        &.isOpen {
          .toggle-icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .drop-down-wrapper {
      transition: all 0.3s;
      max-height: 0;
      padding-top: 15px;
      border-bottom: 1px solid transparent;
      background-color: var(--color-background-page);
      &.isOpen {
        max-height: 500px;
        border-color: var(--color-border-container);
        margin-bottom: 20px;
      }
    }

    .overview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      padding-top: 5px;
      .step {
        display: flex;
        align-items: center;
        color: #1890ff;
        cursor: pointer;
        padding: 0;
        transition: background-color 0.3s;
        &.active {
          color: #1890ff;
          .label {
            text-decoration: underline;
          }
          .icon {
          }
        }

        .label {
          font-weight: 500;
          margin-bottom: 20px;
        }

        &:not(.active) {
          &:not(.disabled) {
            &.ok {
              color: #187c18;
            }

            &.mandatory {
              color: #bb9a1c;
            }

            &.error {
              color: red;
            }

            &.default {
              color: #aaa;
            }
          }
        }

        &.disabled {
          cursor: not-allowed;
          color: #aaa;
          .label {
            font-style: italic;
            font-weight: 300;
          }
        }

        .icon {
          width: 45px;
          font-size: 1.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-background-page);
          transition: all 0.3s;
          padding: 0 10px;
          z-index: 1;
          margin-bottom: 20px;
        }

        &.default {
          .icon {
            font-size: 0.8em;
          }
        }
      }

      & > * {
        &:first-child {
          :after {
            position: absolute;
            top: 16px;
            left: 22px;
            display: block;
            bottom: 25px;
            width: 2px;
            content: '';
            background-color: #ccc;
          }
        }
      }
    }
  }
`;
