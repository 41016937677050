import { decode, encode, salt } from '../security';

const PLATFORM_01 = '050102';

export const cacheItem = (name, item, options = {}) => {
  if (name === 'x-connex-id') {
    name = PLATFORM_01;
  }

  const { 'x-api-key': apiKey } = localStorage.getItem('awsConfig') ?? {};

  if (name === PLATFORM_01 && item !== null) {
    return localStorage.setItem(name, encode(`${name}:${salt}:${apiKey}`, item), options);
  }

  let itemToPersist = item;

  if (typeof item === 'object') {
    const stringifiedObject = JSON.stringify(item);
    const encodedObject = `${name}:${encode(`${name}:${salt}:${apiKey}`, stringifiedObject)}`;
    itemToPersist = encodedObject;
  }

  return localStorage.setItem(name, itemToPersist, options);
};

export const clearCachedItem = name => {
  if (name === 'x-connex-id') {
    name = PLATFORM_01;
  }
  localStorage.removeItem(name);
};

export const getCachedItem = (name, defaultValue = null) => {
  if (name === 'x-connex-id') {
    name = PLATFORM_01;
  }

  const { 'x-api-key': apiKey } = localStorage.getItem('awsConfig') ?? {};
  let value = localStorage.getItem(name);

  if (value !== null && name === PLATFORM_01) {
    return decode(`${name}:${salt}:${apiKey}`, value);
  }

  if (typeof value === 'string' && value?.startsWith(`${name}:`)) {
    value = JSON.parse(decode(`${name}:${salt}:${apiKey}`, value.substr(name.length + 1)));
  }

  return value ?? defaultValue;
};
