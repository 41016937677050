import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useDriverCommentSetup, useVehicleSetup } from '../../MasterDataProvider';
import { useTicketContext } from '../../TicketContext';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const { useDrawerContext } = Drawer;

const CommentList = () => {
  const vehicleSetup = useVehicleSetup();
  const driverCommentSetup = useDriverCommentSetup();
  const companySetup = useCompanySetup();

  const { ticket } = useTicketContext();

  const { values, setFieldValue } = Form.useFormContext();

  const { closeDrawer } = useDrawerContext();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [ticket?.vehicleId, vehicleSetup.data]);

  const activeComments = React.useMemo(
    () =>
      driverCommentSetup.data
        ?.filter?.(comment => comment.status === 'ACTIVE')
        ?.filter?.(comment =>
          companySetup?.data?.isMulticountry === true
            ? comment?.countries?.includes(ticket?.origin?.address?.countryCode) || !comment?.countries?.length
            : comment
        )
        ?.filter?.(comment => comment?.vehicleTypes?.includes(vehicleType) || !comment?.vehicleTypes?.length) || [],
    [companySetup?.data?.isMulticountry, driverCommentSetup.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  const handleSave = React.useCallback(
    comment => {
      const targetComment = find(driverCommentSetup.data, { crn: comment });
      const newCommentValue = `${values?.comments?.driver?.trim?.() || ''}\n${
        targetComment.description || targetComment.name
      }`.trim();
      setFieldValue('comments.driver', newCommentValue);
      closeDrawer();
    },
    [closeDrawer, driverCommentSetup.data, setFieldValue, values]
  );

  const options = React.useMemo(
    () =>
      activeComments?.length
        ? activeComments.map(comment => ({ id: comment.crn, label: comment.description || comment.name }))
        : [],
    [activeComments]
  );

  return (
    <Styled className={cn('loading-point')}>
      <Core.Spinner spin={vehicleSetup.isLoading || driverCommentSetup.isLoading}>
        {/*<Select options={options} onChange={setComment} value={comment} showSearch={false} />*/}
        {options.map(option => {
          return (
            <div
              style={{
                border: '1px solid #ccc',
                padding: '3px 10px',
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundColor: '#fafafa',
              }}
              onClick={() => {
                handleSave(option.id);
              }}
            >
              {option.label}
            </div>
          );
        })}
        {/*<Button type="primary" onClick={handleSave} stringId="addComment" data-testid="add-comment-button" />*/}
      </Core.Spinner>
    </Styled>
  );
};

export default CommentList;
