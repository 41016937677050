import { css } from 'styled-components';

export default css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
  padding: 0 25px;
  .caption {
    display: flex;
    width: 100%;
    .ticket-number {
      margin-right: 25px;
    }
  }
  img {
    height: 90px;
    left: 28px;
  }
  .signature-actions {
    
  }
`;
