import { css } from 'styled-components';

export default css`
  display: flex;
  height: 40px;
  width: 100%;
  margin: 10px;
  //border-bottom: 1px solid #ccc;
  align-items: center;
  .left-side {
    flex: 1;
    display: flex;
    align-items: center;
    [data-testid='back-button'] {
      margin-right: 20px;
    }
    //gap: 15px;
  }
  .right-side {
    display: flex;
    align-items: center;
    & > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    //gap: 20px;
    //justify-content: flex-end;
  }
`;
