import { Form, Localization } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();

  if (!values?.returnReason || values?.driverDidNotAdd) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>Returned Concrete</td>
      <td></td>
      <td data-testid={`${testId}-concrete-value`}>
        <Localization.Uom uom={values?.returnConcreteOnTruck} />
      </td>
      <td data-testid={`${testId}-reason-value`}>{values?.returnReason?.description || ''}</td>
    </tr>
  );
};
