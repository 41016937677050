import cn from 'classnames';
import { Core, Drawer, Form, Icons, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { useTicketContext } from '../../../../TicketContext';
import { CommentEditor } from './CommentEditor';
import { CustomerCommentButton } from './CustomerCommentButton';

const { Button } = Core;
const { Column, Row, Container } = Layout;
const { Subtitle, Helper } = Typography;
const { Translate, ShortDateTime } = Localization;
const { Edit } = Icons;

const Styled = styled(Column)`
  & > .row {
    & > .column {
      margin-right: 10px;
    }
  }
  [data-testId='driverCommentValue'] {
    textarea {
      height: 70px;
      width: 100%;
      border: 0;
      background: transparent;
    }
  }
`;

export const Comments = () => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();

  const companySetup = useCompanySetup();

  const handleCommentClick = React.useCallback(() => {
    if (!finalized || values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') return;

    openDrawer({
      titleStringId: 'customerSignature',
      component: <CommentEditor onFinally={closeDrawer} />,
    });
  }, [closeDrawer, finalized, openDrawer, values?.customerStatus]);

  return (
    <Styled className={cn('comments')}>
      <Row flex={1}>
        {companySetup.data?.isDriverCommentEnabled && (
          <Column>
            <Container flex={1} style={{ padding: '5px 10px' }}>
              <Subtitle>
                <Translate stringId="driverComment" data-testid="driverCommentLabel" />
              </Subtitle>
              <div className="comment-section" style={{ padding: '5px' }}>
                <div data-testid="driverCommentValue">{values?.comments.driver}</div>
              </div>
            </Container>
          </Column>
        )}
        {companySetup.data?.isCustomerCommentEnabled && (
          <Column>
            <Container flex={1} style={{ padding: '5px 10px' }} onClick={handleCommentClick}>
              <Subtitle>
                <Translate stringId="customerComment" data-testid="customerCommentLabel" />
              </Subtitle>
              <div className="comment-section" style={{ padding: '5px' }}>
                {finalized &&
                !values?.comments.customer &&
                !(values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') ? (
                  <CustomerCommentButton onClick={handleCommentClick} />
                ) : (
                  <div data-testid="customerCommentValue">{values?.comments.customer}</div>
                )}
              </div>
            </Container>
          </Column>
        )}
      </Row>
    </Styled>
  );
};
