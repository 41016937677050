import common from '../common';
import errors from './errors';
import themes from './theme';
import features from '../en-US/features';

export default {
  ...common,

  mobileTicket: 'Bon de livraison en ligne',
  ...features,

  save: 'Sauvegarder',

  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  login: 'Se connecter',
  required: 'Obligatoire',

  light: 'Clair',
  dark: 'Sombre',

  rememberMe: 'Se souvenir de moi',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Politique de confidentialité',

  customer: 'Client',
  project: 'Projet',

  isBillable: 'Facturable',
  dispatchDateTime: 'Date',
  orderId: 'Commande',
  ticketId: 'Livraison',
  location: 'Localisation',
  vehicleTypeName: 'Véhicule',
  product: 'Produit',
  salesTax: 'Taxe de vente',
  totalPrice: 'Total',
  destination: 'Destination',
  productId: 'ID du produit',
  statusCode: 'Statut',
  vehicleTypeId: 'ID du type de véhicule',
  locationId: 'ID du lieu',
  customerId: 'ID du client',
  projectId: 'ID du projet',
  dispatchTime: 'Heure',

  showAll: 'Afficher tout',
  hideAll: 'Cacher tout',
  undoChanges: 'Annuler les modifications',
  resetColumns: 'Réinitialiser les colonnes',

  displayRowCount: `Afficher {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Filtres de colonnes',

  visible: 'Visible',
  hidden: 'Cachés',

  itemDetail: "Détail de l'article",

  quantity: 'Quantité',

  unloadingMethod: 'Méthode de déchargement',

  time: 'Heure',
  eventStatus: 'Statut',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
  one {1 Percent}
  other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
  one {1 Degree Celsius}
  other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
  one {1 Degree Fahrenheit}
  other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
  one {1 Barrel}
  other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
  one {1 Centilitre}
  other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
  one {1 Decilitre}
  other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  one {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,

  mixIdLabel: 'ID/Description du mélange',
  orderedQtyLabel: 'Qté commandée',
  loadedQtyLabel: 'Qté chargée',

  addedByCustomer: 'Ajouté à la demande du client',

  PRINTED: 'Assigné',
  LOADING_STARTED: 'Début du chargement',
  LOADING_COMPLETE: 'Fin de la charge',
  TO_JOB: "Quitter l'usine",
  ARRIVE_JOB: 'Arrivée sur le chantier',
  UNLOADING: 'Début du déchargement',
  END_UNLOADING: 'Fin du déchargement',
  LEAVE_JOB: 'Quitter le chantier',
  IN_YARD: 'Sur Centrale',
  PUMPING_STARTED: 'Commencez à pomper',
  PUMPING_COMPLETE: 'Fin du pompage',
  READY_TO_PUMP: 'Prêt à pomper',
  FIRST_WCCONTACT: 'Contact eau-ciment',

  plantName: "Nom de l'usine",
  plantAddress: "Adresse de l'usine",
  loadingScheduleStart: 'Temps de chargement prévu',

  deliveryAddress: 'Adresse de livraison',
  phoneNumber: 'Numéro de téléphone',
  unloadingScheduleStart: 'Temps de déchargement prévu',
  agreedUnloading: 'Temps de déchargement convenue',

  deliveryInformation: 'Informations sur la livraison',
  next: 'Suivant',

  material: 'Matériel',
  loadingPoint: 'Point de chargement',
  unloadingPoint: 'Point de déchargement',

  comment: 'Commentaire',

  sapDeliveryNumber: 'Numéro de livraison SAP (numéro BCC)',
  salesOrderNumber: "Numéro de commande et ligne d'article",
  wcDateTime: 'Date et heure du contact W/C',
  po: 'Numéro de PO',
  orderedByName: 'Commande passée par nom',
  orderedByPhone: 'Commande passée par numéro de téléphone',
  resourceId: 'ID du véhicule',
  totalLoadedQty: 'Quantité totale chargée, y compris ce chargement',
  spacing: 'Intervalle de chargement',
  deliveryFlow: 'Cadence de livraison',
  timeOnSite: 'Temps total sur site/temps de déchargement prévu',
  salesText: "Texte de vente (ID de l'article)",
  strengthClass: 'Classe de résistance',
  environmentExposure: "Exposition à l'environnement",
  metExposureClasses: "Classe d'exposition atteinte",
  dmax: 'Dmax',
  standardCert: 'Norme (certification)',
  workabilityTarget: 'Objectif de maniabilité du client',
  stoneQuality: 'Qualité de la pierre',
  sandQuality: 'Qualité du sable',
  targetWC: 'Rapport W/C cible de la recette',
  controlClass: 'Classe de contrôle',
  chlorideClass: 'Classe de chlorure',
  typeAndStrength1: 'Type de ciment et classe de résistance 1',
  typeAndStrength2: 'Type de ciment et classe de résistance 2',
  typeOfAddition: "Type d'adjuvant",
  typeOfAdmixture: "Type d'adjuvant",
  orderedTemp: 'Température commandée',
  fibers1: 'Type et contenu des fibres 1',
  fibers2: 'Type et contenu des fibres 2',
  calculatedMaturityTime: 'Temps de maturité calculé à partir du temps de contact W/C',

  generalDelivery: 'Informations générales sur la livraison',
  materialInfo: 'Informations sur les matériaux',

  water: 'Eau',
  materialService: 'Matériel / Service',
  returnedMaterial: 'Matériel retourné',

  waterAdded: 'Eau ajoutée',
  concreteOnTruck: 'Béton sur camion',
  concreteOnTruckUom: 'Béton par défaut sur camion UOM',
  reason: 'Raison',
  customerAddedWater: "Le client a ajouté de l'eau",
  addWater: "Ajout d'eau",
  addedAt: 'Ajouté à',

  addMaterialService: 'Ajout de matériel/service',
  customerAddedProduct: 'Produit ajouté par le client',

  addReturnedMaterial: 'Ajout de matériel retourné',
  leftover: 'Restant',

  submitAcceptance: "Soumettre l'acceptation",
  customerAcceptance: 'Acceptation du client',
  finalize: 'Finaliser',

  materialSummary: 'Résumé du matériel',
  mix: 'Mélange',
  ordered: 'Commandé',
  loaded: 'Quantité chargée',
  totalOrdered: 'Quantité totale de la commande',
  loadedOrderQuantity: 'Quantité totale chargée',
  loadedOrder: 'Déjà chargé',

  driverComment: 'Commentaire du conducteur',
  customerComment: 'Commentaire du client',
  editComment: 'Modifier le commentaire',
  addComment: 'Ajouter un commentaire',
  additionalInfo: 'Informations supplémentaires',
  onBoard: 'A bord',
  driver: 'Chauffeur',
  commentInstruction: 'Commentaire Instruction',
  signatureInstruction: 'Signature du client',
  customerSignature: 'Signature du client',

  general: 'Général',

  status: 'Statut',
  updateStatusTime: 'Mise à jour du statut Heure',
  delete: 'Supprimer',
  cancel: 'Annuler',
  back: 'Retour',
  add: 'Ajouter',
  ok: 'OK',
  lastRefresh: 'Dernier rafraîchissement',
  refreshEnabled: 'Activation du rafraîchissement',

  id: 'ID',
  name: 'Nom',
  invoiceable: 'Facturable',
  provideSignature: 'Fournir une signature',
  ticket: 'Bon de livraison',
  details: 'Détails',
  qrCode: 'Code QR',

  driverAcceptanceReason: 'Raison',
  driverAcceptance: 'Acceptation du chauffeur',

  'payment-method': 'Méthodes de paiement',
  'add-payment-method': 'Ajouter une méthode de paiement',

  'reason-code': 'Code de raison',
  'add-reason-code': 'Ajouter un code de raison',

  'qr-code': 'Code QR',
  'unloading-method': 'Méthodes de déchargement',
  'add-unloading-method': 'Ajouter une méthode de déchargement',

  'driver-comment': 'Commentaires du conducteur',
  'add-driver-comment': 'Ajouter un commentaire un conducteur ',
  'driver-sellable': 'Conducteur vendable',

  vehicle: 'Véhicules',
  'add-vehicle': 'Ajouter un véhicule',

  'vehicle-type': 'Types de véhicules',
  vehicleTypes: 'Types de véhicules',
  vehicleType: 'Type de véhicule',
  'add-vehicle-type': 'Ajouter le type de véhicule',
  countries: 'Pays',
  country: 'Pays',

  type: 'Type',
  uom: 'UOM',
  mandatory: 'Obligatoire',

  qrTemplate: 'Modèle de code QR',
  includeMixInfo: 'Inclure les informations sur le mélange dans le code QR',
  includeTicketEvents: 'Inclure les événements liés aux livraisons dans un code QR',

  quantityField: 'Activation du champ Quantité',
  concreteOnTruckField: 'Enable Concrete On Truck Field',
  timeField: 'Activation du champ Heure',
  reasonField: 'Activation du champ Raison',

  driverInstructions: 'Instructions pour le chauffeur',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'La valeur ne peut pas dépasser la quantité chargée de {loadedQuantity}.',
  maxQuantity: 'Quantité maximale',
  maxQuantityError: 'La quantité ne peut pas dépasser la quantité maximale définie de {maxQty}',

  notAuthorized: 'Non autorisé',

  enterTruckNumber: 'Entrer le numéro du camion',
  setTruckNumber: 'Définir le numéro du camion',

  commentList: 'Liste des commentaires',
  list: 'Liste',

  gridPreferences: 'Liste',

  loadingUnloadingPoints: 'Points de chargement/déchargement',

  activity: 'Activity',

  crn: 'CRN',
  update: 'Actualisation',

  CONCRETE: 'Béton',
  WATER: 'Eau',
  ADDITIVE: 'Additif',

  'submit-signature-button': 'Accepter',
  'cancel-signature-button': 'Annuler',

  uomCode: 'Default UOM',
  'product-type': 'Types de produits',
  'add-product-type': 'Ajouter un type de produit',
  'add-product': 'Ajouter un produit',
  'setup_driver-comment': 'Ajouter un produit',
  setup_product: 'Produits',
  'setup_product-type': 'Types de produits',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Codes de raison',
  'setup_unloading-method': 'Méthodes de déchargement',
  'setup_entity-mt': 'Société',
  setup_vehicle: 'Véhicules',
  'setup_vehicle-type': 'Types de véhicules',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Types de véhicules',

  qrCodeTemplate: 'Modèle de code QR',

  waterConfig: 'Optional Fields',

  waterProduct: 'Eau',
  waterProductType: 'Type de produit eau',
  defaultWaterProduct: "Produit d'eau par défaut",

  invalidTemplate: 'Modèle non valide',

  siteAddress: 'Adresse du site',
  siteContactName: 'Nom du contact sur le site',
  siteContactPhoneNumber: 'Numéro de téléphone du contact sur le site',

  'clear-signature-button': 'Supprimer la signature',

  customerCommentEnabled: 'Activer le commentaire client',
  driverCommentEnabled: 'Activer le commentaire chauffeur',

  driverCommentMaxCharacters: 'Longueur maximale',
  customerCommentMaxCharacters: 'Longueur maximale',

  timeZone: 'Fuseau horaire',

  driverDidNotAdd: "N'a pas été ajouté",
  maxLineItems: "Nombre maximal d'éléments de ligne",
  oneLineItem: 'Doit avoir au moins 1 ligne',

  signatureSiteContact: 'Nom du client',
  ticketEvents: 'Événements liés aux billets',
  hideEvent: "Cacher l'événement",
  editable: 'Modifiable',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Temps de statut',

  materialAndDeliveryInfo: 'Informations sur le matériel et la livraison',

  customerRejection: 'Rejet du client',
  'submit-rejection-button': 'Soumettre le rejet',
  accept: 'Accepter',
  reject: 'Rejeter',

  signature: 'Signature',
  'no-signature': 'Pas de signature',

  unFinalizePassword: 'Un-Finalize mots de passe',

  waterInWorkflow: "L'ajout d'eau est obligatoire",
  multicountry: 'Multicountry',

  timestampAfterSequence: 'AVERTISSEMENT : Le temps ne doit pas tomber avant le temps précédent',
  timestampBeforeSequence: 'AVERTISSEMENT : Le temps ne doit pas tomber après le temps suivant',

  noRestQuantity: 'Aucune quantité retournée',

  returnedMaterialMandatory: 'Quantité retournée obligatoire',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Activer le code QR',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  errors,
  themes,
};
