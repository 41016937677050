import { toUtcIso } from 'connex-cds';
import { isObject } from 'lodash';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mobileTicket } from '../../../../api';
import { usePostMessageListener } from './datastore/usePostMessageListener';
import { WaitingForTicket } from './WaitingForTicket';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCompanySetup } from './MasterDataProvider';

const TicketContext = React.createContext();

export const useTicketContext = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error(`useTicketContext cannot be rendered outside of the TicketContext context provider`);
  }
  return context;
};

export const useTicket = (ticketRef, enabled) => {
  const { entityRef } = useParams();
  const companySetup = useCompanySetup();

  const query = useQuery({
    queryKey: ['ticket', entityRef, ticketRef],
    queryFn: () => mobileTicket.getTicket({ entityRef, ticketRef }),
    refetchInterval: (companySetup?.data?.pollingInterval || 0) * 1000,
    staleTime: Infinity,
    enabled,
  });

  if (!ticketRef) {
    return null;
  }

  return query;
};

export const TicketContextProvider = ({ children, driverExperience = false }) => {
  const { entityRef, truckNumber } = useParams();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = React.useState(false);
  const [fetchedDateTime, setFetchedDateTime] = React.useState(toUtcIso(DateTime.now()));
  const [finalized, setFinalized] = React.useState(false);
  const [currentTicketRef, setCurrentTicketRef] = React.useState();
  const [queryEnabled, setQueryEnabled] = React.useState(false);
  const queryClient = useQueryClient();

  const ticketQuery = useTicket(currentTicketRef, queryEnabled);

  React.useEffect(() => {
    setQueryEnabled(!!currentTicketRef);
  }, [currentTicketRef]);

  const reset = React.useCallback(() => {
    setFinalized(false);
    setQueryEnabled(false);
    queryClient.invalidateQueries({ queryKey: ['ticket', entityRef, currentTicketRef] });
    setCurrentTicketRef(null);

    if (driverExperience) {
      navigate(`driver/${entityRef}}${truckNumber}${driverExperience ? '' : '/mt'}`);
    } else {
      navigate(`/${entityRef}/${truckNumber}/mt`);
    }
  }, [currentTicketRef, driverExperience, entityRef, navigate, queryClient, truckNumber]);

  const refreshTicket = React.useCallback(
    async entityRef => {
      setRefreshing(true);
      const updatedTicket = await mobileTicket.getTicket({ entityRef, ticketRef: ticketQuery?.data?.crn });

      if (updatedTicket.isVoided) {
        reset();
        return;
      }

      queryClient.setQueryData(['ticket', entityRef, updatedTicket.crn], updatedTicket);

      setFetchedDateTime(toUtcIso(DateTime.now()));
      setRefreshing(false);
    },
    [queryClient, reset, ticketQuery?.data?.crn]
  );

  const listener = React.useCallback(
    event => {
      // alert(JSON.stringify(event?.data || {}));

      let message;

      // TODO: move this into the usePostMessageListener hook.
      if (isObject(event?.data)) {
        message = event?.data;
      } else {
        try {
          message = JSON.parse(event?.data);
        } catch (e) {
          message = event?.data;
        }
      }

      if (message?.type === 'status-event') {
        const newTicket = {
          ...ticketQuery?.data,
          ticketEvents: {
            ...ticketQuery?.data?.ticketEvents,
            [message.name]: {
              eventDateTime: message.timestamp,
            },
          },
        };
        queryClient.setQueryData(['ticket', entityRef, newTicket?.crn], newTicket);
      }

      if (message?.type === 'ticket') {
        if (message?.active === false) {
          reset();
        }
        if (message?.active === true) {
          reset();
          const ticketRef = message.crn;
          if (ticketRef) {
            setCurrentTicketRef(ticketRef);
          }
        }
      }
    },
    [entityRef, queryClient, reset, ticketQuery?.data]
  );

  usePostMessageListener(listener);

  if (!ticketQuery?.data) {
    return <WaitingForTicket busy={ticketQuery?.isLoading} setTicketRef={setCurrentTicketRef} />;
  }

  return (
    <TicketContext.Provider
      value={{ ticket: ticketQuery?.data, refreshTicket, refreshing, fetchedDateTime, reset, finalized, setFinalized }}
    >
      {children}
    </TicketContext.Provider>
  );
};
