import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,

  mobileTicket: 'Mobile Ticket',
  ...features,

  save: 'Gem',
  username: 'Brugernavn',
  password: 'Password',
  login: 'Log ind',
  required: 'Obligatorisk',

  light: 'Lys',
  dark: 'Mørk',

  rememberMe: 'Husk mig',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Fortrolighedspolitik',

  customer: 'Kunde',
  project: 'Projekt',

  isBillable: 'Kan kontaktes',
  dispatchDateTime: 'Dato',
  orderId: 'Order',
  ticketId: 'Følgeseddel',
  location: 'Lokation',
  vehicleTypeName: 'Enhed',
  product: 'Produkt',
  salesTax: 'Salgs moms',
  totalPrice: 'Total',
  extendedPrice: 'Forøget pris',
  destination: 'Destination',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Truck ID',
  locationId: 'Lokation ID',
  customerId: 'Kunde ID',
  projectId: 'Projekt ID ',
  dispatchTime: 'Time',

  showAll: 'Vis alt',
  hideAll: 'Gem alt',
  undoChanges: 'slet ændringer',
  resetColumns: 'Reset kolonner',

  displayRowCount: `Vis {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Kolonne filter',

  visible: 'Synligt',
  hidden: 'Gemt',

  topSection: 'Øverste Sektion',

  itemDetail: 'Vare detaljer',

  quantity: 'Antal',

  unloadingMethod: 'Aflæsnings type',

  time: 'Tid',
  eventStatus: 'Status',

  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',

  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,

  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
  one {1 Percent}
  other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
  one {1 Degree Celsius}
  other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
  one {1 Degree Fahrenheit}
  other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
  one {1 Barrel}
  other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
  one {1 Centilitre}
  other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
  one {1 Decilitre}
  other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  one {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,

  mixIdLabel: 'Blanding / Beskrivelse',
  orderedQtyLabel: 'Bestilt mængde',
  loadedQtyLabel: 'Læsset mængde',

  addedByCustomer: 'Added By Customer',

  PRINTED: 'Tildelt',
  LOADING_STARTED: 'Loading Started',
  LOADING_COMPLETE: 'Loading Complete',
  TO_JOB: 'Fra fabrik',
  ARRIVE_JOB: 'Start aflæsningstid',
  UNLOADING: 'Start aflæsningstid',
  END_UNLOADING: 'Slut aflæsningstid',
  LEAVE_JOB: 'Fra byggeplads',
  IN_YARD: 'I gården',
  PUMPING_STARTED: 'Pumping Started',
  PUMPING_COMPLETE: 'Pumping Complete',
  READY_TO_PUMP: 'Ready to Pump',
  FIRST_WCCONTACT: 'First Water Cement Contact',

  plantName: 'Fabriks navn',
  plantAddress: 'Fabriks adresse',
  loadingScheduleStart: 'Planlagt dato og læssetid',

  deliveryAddress: 'Leveringsadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Planlagt dato og aflæssetid',
  agreedUnloading: 'Ordered Unloading Times',

  deliveryInformation: 'Leverings information',
  next: 'Næste',

  material: 'Materiale',
  loadingPoint: 'Læssested',
  unloadingPoint: 'Aflæsningssted',

  comment: 'Kommentarer',

  sapDeliveryNumber: 'SAP Leveringsnummer',
  salesOrderNumber: 'Ordernummer og orderrække',
  wcDateTime: 'Dato og Tid W/C kontakt',
  po: 'PO Nummer',
  orderedByName: 'Bestillers navn',
  orderedByPhone: 'Bestillers telefonnummer',
  resourceId: 'Resurce ID',
  totalLoadedQty: 'Total læsset kvantitet incl.dette læs',
  spacing: 'Interval',
  deliveryFlow: 'Leverings flow',
  timeOnSite: 'Total på byggeplads tid/ Bestilt aflæsningstid',
  salesText: 'Salgs tekst',
  strengthClass: 'Styrkeklasse ',
  environmentExposure: 'Miljøpåvirkning',
  metExposureClasses: 'Eksponeringsklasse',
  dmax: 'Dmax',
  standardCert: 'Standard',
  workabilityTarget: 'Konsistens',
  stoneQuality: 'Stenkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'Tilstræbt V/C forh.',
  controlClass: 'Kontrolklasse',
  chlorideClass: 'Chloridklasse',
  typeAndStrength1: 'Cement 1',
  typeAndStrength2: 'Cement 2',
  typeOfAddition: 'Tilsætningsmaterialer',
  typeOfAdmixture: 'Tilsætningsmiddel',
  orderedTemp: 'Bestilt temperatur',
  fibers1: 'Type og mængde af fiber 1',
  fibers2: 'Type og mængde af fiber 2',
  calculatedMaturityTime: 'Modenhedstid',

  generalDelivery: 'Generel leverings information',
  materialInfo: 'Materiale information',

  water: 'Vand',
  materialService: 'Materiale/Service',
  returnedMaterial: 'Returbeton',

  waterAdded: 'Vand tilsat',
  concreteOnTruck: 'Beton på truck',
  concreteOnTruckUom: 'Default Concrete on Truck UOM',
  reason: 'Grund',
  customerAddedWater: 'Kunden tilsatte vand',
  addWater: 'Tilsæt vand',
  addedAt: 'Added at',

  addMaterialService: 'Tilsæt materiale/service',
  customerAddedProduct: 'Kunden tilsat produkt',

  addReturnedMaterial: 'Tillæg Returneret beton',
  leftover: 'Restbeton',

  submitAcceptance: 'Accept',
  customerAcceptance: 'Kundens accept',
  finalize: 'Finalize',

  materialSummary: 'Summering',
  mix: 'Blanding',
  ordered: 'Bestilt',
  loaded: 'Læsset',
  totalOrdered: 'Total Order Quantity',
  loadedOrderQuantity: 'Loaded Order Quantity',
  loadedOrder: 'Loaded Order',

  driverComment: 'Chauffør kommentarer',
  customerComment: 'Kundens kommentarer',
  editComment: 'Slet kommentarer',
  addComment: 'ekstra kommentar',
  additionalInfo: 'ekstra information',
  onBoard: 'læsset',
  driver: 'Chauffør',
  commentInstruction: 'Kommentarer felt',
  signatureInstruction: 'Underskrift felt',
  customerSignature: 'Kundens underskrift',

  general: 'Generelt',
  status: 'Status',
  updateStatusTime: 'Opdatere tids status',
  delete: 'Slet',
  cancel: 'Annullere',
  back: 'Tilbage',
  add: 'Tilføj',
  ok: 'OK',
  lastRefresh: 'Last Refresh',
  refreshEnabled: 'Enable Refresh',

  id: 'ID',
  name: 'Navn',
  invoiceable: 'Fakturerings klar',
  provideSignature: 'Signatur',
  ticket: '( Kørekort )',
  details: 'Detaljer',
  qrCode: 'QR',

  driverAcceptanceReason: 'Grunde',
  driverAcceptance: 'Chauffør godkendelse',

  'reason-code': 'Begrundelses kode',
  'add-reason-code': 'Tilføj begrundelses kode',

  'qr-code': 'QR Kode',
  'unloading-method': 'Aflæsningstype',
  'add-unloading-method': 'Tilføj Aflæsningstype',

  'driver-comment': 'Chauffør kommentarer',
  'add-driver-comment': 'Tilføj Chauffør kommentarer',
  'driver-sellable': 'Driver Sellable',

  vehicle: 'Vehicles',
  'add-vehicle': 'Add Vehicle',

  'vehicle-type': 'Vehicle Types',
  vehicleTypes: 'Vehicle Types',
  vehicleType: 'Vehicle Type',
  'add-vehicle-type': 'Add Vehicle Type',
  countries: 'Countries',
  country: 'Country',

  type: 'Type',
  uom: 'Målenhed',
  mandatory: 'Obligatorisk',

  qrTemplate: 'QR-kode mal',
  includeMixInfo: 'Inkluderer materialeinformation i QR-kode',
  includeTicketEvents: 'Inkluderer følgeseddelshændelser i QR-kode',

  quantityField: 'Aktivere mængde felt',
  concreteOnTruckField: 'Aktivere betonfelt på bilen',
  timeField: 'Aktivere tids felt',
  reasonField: 'Aktivere årsags felt ',

  driverInstructions: 'Chauffør instruktioner',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'værdien kan ikke overskride blandede mængde på {loadedQuantity}',
  maxQuantity: 'Maximal mængde',
  maxQuantityError: 'Quantity cannot exceed defined maximum quantity of {maxQty}',

  notAuthorized: 'Not Authorized',

  enterTruckNumber: 'Enter Truck Number',
  setTruckNumber: 'Set Truck Number',

  commentList: 'Comments List',
  list: 'List',

  gridPreferences: 'Grid Preferences',

  loadingUnloadingPoints: 'Loading/Unloading Points',

  activity: 'Activity',

  crn: 'CRN',
  update: 'Update',

  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',

  'submit-signature-button': 'Accept',
  'cancel-signature-button': 'Cancel',

  uomCode: 'Default UOM',
  'product-type': 'Product Types',
  'add-product-type': 'Add Product Type',
  'add-product': 'Add Product',
  'setup_driver-comment': 'Driver Comments',
  setup_product: 'Products',
  'setup_product-type': 'Product Types',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Reason Codes',
  'setup_unloading-method': 'Unloading Methods',
  'setup_entity-mt': 'Company',
  setup_vehicle: 'Vehicles',
  'setup_vehicle-type': 'Vehicle Types',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optional Fields',

  waterProduct: 'Water',
  waterProductType: 'Water Product Type',
  defaultWaterProduct: 'Default Water Product',

  invalidTemplate: 'Invalid Template',

  siteAddress: 'Site Address',
  siteContactName: 'Site Contact Name',
  siteContactPhoneNumber: 'Site Contact Phone Number',

  'clear-signature-button': 'Clear Signature',

  customerCommentEnabled: 'Enable Customer Comment',
  driverCommentEnabled: 'Enable Driver Comment',

  driverCommentMaxCharacters: 'Max Length',
  customerCommentMaxCharacters: 'Max Length',

  timeZone: 'Time Zone',

  driverDidNotAdd: 'Driver Did Not Add',
  maxLineItems: 'Max Number of Line Items',
  oneLineItem: 'Must have at least 1 line item',

  signatureSiteContact: 'Customer Name',
  ticketEvents: 'Ticket Events',
  hideEvent: 'Hide Event',
  editable: 'Editable',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Times',

  materialAndDeliveryInfo: 'Material and Delivery Information',

  customerRejection: 'Customer Rejection',
  'submit-rejection-button': 'Submit Rejection',
  accept: 'Accept',
  reject: 'Reject',

  signature: 'Signature',
  'no-signature': 'No Signature',

  unFinalizePassword: 'Un-Finalize Password',

  waterInWorkflow: 'Enable Water Step in Workflow',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WARNING: Time should not be before previous timestamp',
  timestampBeforeSequence: 'WARNING: Time should not be after next timestamp',

  noRestQuantity: 'No Rest Quantity',

  returnedMaterialMandatory: 'Must Interact With Returned Material Step',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  errors,
  themes,
};
