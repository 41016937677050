import { Core, Form, Localization, Typography } from 'connex-cds';
import React from 'react';

export const SetupListHeader = ({ onAdd, typeId, onBack }) => {
  const { Components } = Form.useFormContext();

  return (
    <div className="header">
      <div className="left">
        <Core.Button size="small" type="primary" stringId="back" data-testid="back-button" onClick={onBack} />
        <Typography.H3>
          <Localization.Translate stringId={`setup_${typeId}`} data-testid="page-title" />
        </Typography.H3>
      </div>
      <div className="right">
        {onAdd && <Components.AddButton onClick={onAdd} size="small" stringId={`add-${typeId}`} />}
      </div>
    </div>
  );
};
