import cn from 'classnames';
import { Drawer, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { FormSection } from './FormSection';

const { Row } = Layout;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .list-section {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .list-component {
      display: flex;
      flex-direction: column;
    }
  }
  .row {
    margin-bottom: 5px;
    .left {
      flex: 1;
    }
  }
`;

export const ListSection = ({ requirementFulfilled, CustomListComponent }) => {
  const {
    Components: { AddButton, ListComponent, RequiredIndicator },
    resetMode,
    parentFormContext: { values: parentValues },
  } = Form.useFormContext();

  const { openDrawer } = Drawer.useDrawerContext();

  const companySetup = useCompanySetup();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'addMaterialService',
      component: <FormSection />,
      onClose: resetMode,
      width: '100%',
    });
  }, [openDrawer, resetMode]);

  const addButtonDisabled = React.useMemo(
    () => parentValues?.lineItems?.length >= companySetup.data?.maxLineItems - 1,
    [companySetup.data?.maxLineItems, parentValues?.lineItems?.length]
  );

  return (
    <>
      <Styled className={cn('list')} style={{ overflow: 'visible' }}>
        <Row style={{ alignItems: 'flex-end', flexShrink: '0' }}>
          <div className="left">
            <RequiredIndicator disabled={requirementFulfilled} />
          </div>
        </Row>
        <div className="list-section">
          <ListComponent Component={CustomListComponent} onClick={handleClick} hideWhenEmpty />
        </div>
      </Styled>
      <div style={{ flexShrink: '0', marginTop: '15px', textAlign: 'right' }}>
        <AddButton onClick={handleClick} disabled={addButtonDisabled} />
      </div>
    </>
  );
};
