import { css } from 'styled-components';

export default css`
  margin-bottom: 10px;
  margin-right: 10px;
  table {
    th {
      text-align: left;
    }
  }
`;
