import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  padding-top: 24px;
`;

const { useDrawerContext } = Drawer;

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

const EditStatusTime = ({ statusType }) => {
  const { Components } = Form.useFormContext();

  const { closeDrawer } = useDrawerContext();

  const Component = React.useMemo(() => Components[pascalCase(statusType)], [Components, statusType]);

  return (
    <Styled className={cn('update-status-time')}>
      <Component onDone={closeDrawer} showLabel={false} rolloverDays={1} />
    </Styled>
  );
};

export default EditStatusTime;
