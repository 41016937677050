import { Form, Localization } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-totalPumpedQuantity';

export const TotalPumpedQuantity = () => {
  const { values } = Form.useFormContext();

  if (!values?.totalPumpedQuantity) {
    return null;
  }

  return (
    <Styled className={cn('status-times-mobile')}>
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            Total Pumped Quantity
          </td>
        </tr>
        <tr>
          <td className="header-cell" data-testid={`${testId}-activity-value`}>
            <Localization.Translate stringId="quantity" data-testid="quantity-label" />
          </td>
          <td data-testid={`${testId}-quantity-value`}>
            <Localization.Uom uom={values?.totalPumpedQuantity} />
          </td>
        </tr>
      </table>
    </Styled>
  );
};
