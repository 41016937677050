import { Localization } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';

export const usePreparedContext = (props = {}) => {
  const { ticket, mobileTicket } = props;

  const translateMessage = Localization.useTranslateMessage();
  const { formatLongDateTime } = Localization.useFormat();

  const primaryLineItem = React.useMemo(() => {
    return find(ticket?.lineItems, { isPrimary: true });
  }, [ticket?.lineItems]);

  const ticketEvents = React.useMemo(() => {
    // Build an object that contains the formatted ticket event time strings.
    // An asterisk indicates an eventDateTime that has been edited by the driver.
    const merged = { ...ticket?.ticketEvents, ...mobileTicket?.ticketEvents };
    return Object.keys(merged).reduce(
      (acc, key) => {
        const ticketDt = ticket?.ticketEvents?.[key]?.eventDateTime;
        const mobileTicketDt = merged?.[key]?.eventDateTime;
        if (mobileTicketDt) acc[key] = `${formatLongDateTime(mobileTicketDt)}${ticketDt !== mobileTicketDt ? '*' : ''}`;
        return acc;
      },
      { PRINTED: '', TO_JOB: '', ARRIVE_JOB: '', UNLOADING: '', END_UNLOADING: '', LEAVE_JOB: '', IN_YARD: '' }
    );
  }, [formatLongDateTime, mobileTicket?.ticketEvents, ticket?.ticketEvents]);

  // preparedContext must be flat.
  const preparedContext = React.useMemo(() => {
    const context = {
      printed: ticketEvents?.PRINTED,
      loadingStarted: ticketEvents?.LOADING_STARTED,
      loadingComplete: ticketEvents?.LOADING_COMPLETE,
      toJob: ticketEvents?.TO_JOB,
      arriveJob: ticketEvents?.ARRIVE_JOB,
      unloading: ticketEvents?.UNLOADING,
      endUnloading: ticketEvents?.END_UNLOADING,
      leaveJob: ticketEvents?.LEAVE_JOB,
      inYard: ticketEvents?.IN_YARD,

      loadedQuantity: translateMessage(primaryLineItem?.quantity?.uomCode, { value: primaryLineItem?.quantity?.value }),
      orderedQuantity: translateMessage(ticket?.dispatchOrder?.totalOrdered?.uomCode, {
        value: ticket?.dispatchOrder?.totalOrdered?.value,
      }),
      productId: primaryLineItem?.item?.id,
      productDescription: primaryLineItem?.item?.description,
      'origin.description': ticket?.origin?.description,
      'customerParty.id': ticket?.customerParty?.id,
      sapDeliveryNumber: ticket?.id,
      salesOrderNumber: ticket?.originatorRecordId,
      wcContactDateTime: formatLongDateTime(ticket?.customData?.productDetails?.wcContactDateTime),
      purchaseOrder: ticket?.purchaseOrder,
      vehicleId: ticket?.containers?.[0]?.vehicle?.id,
      totalLoadedQuantity: translateMessage(ticket?.dispatchOrder?.totalTicketed?.uomCode, {
        value: ticket?.dispatchOrder.totalTicketed.value,
      }),
      loadSpacing: translateMessage(ticket?.dispatchOrder?.deliverySpacing?.uomCode, {
        value: ticket?.dispatchOrder?.deliverySpacing?.value,
      }),
      unloadingMethod: ticket?.unloadingMethod?.description,
      matSalesText: ticket?.customData?.productDetails?.matSalesText,
      strengthClass: ticket?.customData?.productDetails?.strengthClass,
      environmentExposure: ticket?.customData?.productDetails?.environmentExposure,
      metExposureClass: ticket?.customData?.productDetails?.metExposureClass,
      dmax: ticket?.customData?.productDetails?.dmax,
      standardCert: ticket?.customData?.productDetails?.certificationText,
      workabilityTarget: ticket?.customData?.productDetails?.customerSlumpTarget,
      stoneQuality: ticket?.customData?.productDetails?.stoneQuality,
      sandQuality: ticket?.customData?.productDetails?.sandQuality,
      targetWc: ticket?.customData?.productDetails?.targetWcRatioRecipe,
      controlClass: ticket?.customData?.productDetails?.controlClass,
      chlorideClass: ticket?.customData?.productDetails?.chlorideClass,
      strengthClass1: ticket?.customData?.productDetails?.strengthClass1,
      strengthClass2: ticket?.customData?.productDetails?.strengthClass2,
      additionType: ticket?.customData?.productDetails?.additionType,
      admixtureType: ticket?.customData?.productDetails?.admixtureType,
      orderedTemp: ticket?.customData?.productDetails?.orderedTemperature,
      fiber1Type: ticket?.customData?.productDetails?.fiber1Type,
      fiber2Type: ticket?.customData?.productDetails?.fiber2Type,
      calculatedMaturityTime: ticket?.customData?.productDetails?.maturityColdHot,
    };

    // Ensure everything is defined.
    return Object.keys(context).reduce((acc, key) => {
      acc[key] = context[key] || '';
      return acc;
    }, {});
  }, [ticketEvents, translateMessage, primaryLineItem, ticket, formatLongDateTime]);

  return preparedContext;
};
