import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { LoadingUnloadingPoints } from '../../../../ticket-details/logistical-info/loading-unloading-points/LoadingUnloadingPoints';
import { StatusTimeline } from './status-timeline/StatusTimeline';
import style from './style';
import { Layout } from 'connex-cds';
import { MaterialSummary } from '../../../../ticket-details/logistical-info/material-summary/MaterialSummary';

const { Column, Row } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const LogisticalInfo = ({ ticket }) => {
  return (
    <Styled className={cn('logistical-info')}>
      <Row flex={2} stretchContent>
        <MaterialSummary data={ticket} />
      </Row>
      <Row flex={1} stretchContent>
        <LoadingUnloadingPoints data={ticket} />
      </Row>
      <Row flex={6} stretchContent>
        <StatusTimeline />
      </Row>
    </Styled>
  );
};
