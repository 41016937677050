import { Form, Localization } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-productSvc';

export const ProductsServices = () => {
  const { values } = Form.useFormContext();

  if (!values?.lineItems?.length) {
    return null;
  }

  return values.lineItems
    .filter(lineItem => !lineItem.isPrimary && !lineItem.driverDidNotAdd)
    .map((materialService, index) => (
      <tr>
        <td data-testid={`${testId}-row-${index}-activity-value`}>{materialService?.item?.description || ''}</td>
        <td data-testid={`${testId}-row-${index}-quantity-value`}>
          <Localization.Uom uom={materialService.quantity} />
        </td>
        <td data-testid={`${testId}-row-${index}-concrete-value`}>
          <Localization.Uom uom={materialService.concreteOnTruck} />
        </td>
        <td data-testid={`${testId}-row-${index}-reason-value`}>{materialService.reason?.description}</td>
      </tr>
    ));
};
