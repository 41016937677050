import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { masterData } from '../../../../api';

export const useInitializeMasterData = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  useQuery({
    queryKey: ['setup', entityRef],
    queryFn: async () => {
      const data = await masterData.getAllActiveMasterData({ entityRef });
      Object?.keys?.(data)?.forEach(key => {
        queryClient.setQueryData(['setup', entityRef, key], [].concat(data[key]));
      });
      return data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
