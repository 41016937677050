import cn from 'classnames';
import { Core, Localization, Typography, usePostMessageContext } from 'connex-cds';
import { sortBy } from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCompanySetup } from '../MasterDataProvider';
import { useSetupConfig } from './config';
import style from './style';

const { useTranslateMessage } = Localization;

const Styled = styled.div`
  ${style}
`;

export const Setup = () => {
  const translateMessage = useTranslateMessage();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    sendMessage({ locationChange: pathname });
  }, [pathname, sendMessage]);

  const handleClick = React.useCallback(
    item => {
      navigate(`${item.type}/${item.key}`);
    },
    [navigate]
  );

  const _config = useSetupConfig();

  const config = React.useMemo(() => {
    const general = Object.keys(_config.general).map(key => ({
      type: 'general',
      icon: _config.general[key].icon,
      key,
      translatedLabel: translateMessage(`setup_${key}`),
    }));
    const masterData = Object.keys(_config.masterData).map(key => ({
      type: 'master-data',
      icon: _config.masterData[key].icon,
      translatedLabel: translateMessage(`setup_${key}`),
      key,
    }));

    return [...general, ...masterData];
  }, [_config.general, _config.masterData, translateMessage]);

  const sortedConfig = React.useMemo(() => {
    const updatedConfig = config.map(item => ({
      ...item,
      sortKey: item.key === 'entity-mt' ? 'company' : item.key,
    }));
    return sortBy(updatedConfig, ['sortKey']);
  }, [config]);

  const companySetup = useCompanySetup();

  return (
    <Core.Spinner spin={companySetup?.isLoading}>
      <Styled className={cn('setup')}>
        <div className="layout-grid">
          {sortedConfig.map(item => {
            return (
              <div onClick={() => handleClick(item)} key={item.key} data-testid={item.key}>
                {item?.icon}
                <span data-stringid={item.key} data-testid={`${item.key}-label`}>
                  {item.translatedLabel}
                </span>
              </div>
            );
          })}
        </div>
      </Styled>
    </Core.Spinner>
  );
};
