import cn from 'classnames';
import { Core, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../../MasterDataProvider';

const Styled = styled.div`
  .buttons {
    display: flex;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
`;

export const CommentEditor = ({ onFinally }) => {
  const { Components, setFieldValue } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const handleClear = React.useCallback(() => {
    setFieldValue('comments.customer', '');
  }, [setFieldValue]);

  return (
    <Styled className={cn('comment-editor')}>
      <Components.CustomerComment validationText={false} maxLength={companySetup.data?.customerCommentMaxCharacters} />
      <div className="buttons">
        <div>
          <Core.Button onClick={handleClear} stringId="clear" data-testid="clear-button" />
        </div>
        <div>
          <Core.Button type="primary" onClick={onFinally} stringId="done" data-testid="done-button" />
        </div>
      </div>
    </Styled>
  );
};
