import { Form, Layout } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import { ListSection } from './ListSection';
import { CustomListComponent } from './CustomListComponent';
import { FormSection } from './FormSection';
import styled from 'styled-components';

const Styled = styled(Layout.Container)`
  flex: 1;
  overflow: visible;
  .list {
    overflow: visible;
    .list-section {
      overflow: visible;
      .list-component {
        overflow: visible;
      }
    }
  }
`;

export const View = () => {
  const {
    fieldConfig,
    parentFormContext: { isValid, values },
  } = Form.useFormContext();

  const hasItems = React.useMemo(() => {
    return !!get(values, fieldConfig.path)?.length;
  }, [fieldConfig.path, values]);

  return hasItems ? (
    <Styled>
      <ListSection requirementFulfilled={isValid} CustomListComponent={CustomListComponent} />
    </Styled>
  ) : (
    <FormSection />
  );
};
