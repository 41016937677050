import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useDataStore } from '../../datastore/DataStore';

const { Time, Translate } = Localization;

const Styled = styled.div`
  & > table:first-child {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: var(--color-background-simpleGrid-header);
      border: 1px solid var(--color-border-simpleGrid-header);
    }

    tr {
      &.hasError {
        td {
          background-color: #fafab0;
          .error {
            display: inline;
          }
        }
      }
      &.active {
        td {
          background-color: var(--color-background-simpleGrid-highlightedRow);
          color: var(--color-text-simpleGrid-highlightedRow);
        }
      }

      td {
        transition: all 0.3s;
        padding: 0 5px;
        border: 1px solid var(--color-border-simpleGrid-cell);
        background-color: var(--color-background-simpleGrid-cell);
        cursor: ${({ $onRowClick, $rowClickDisabled }) => ($onRowClick && !$rowClickDisabled ? 'pointer' : 'inherit')};
        .error {
          color: red;
          display: none;
        }
      }
    }
  }
`;

const testId = 'statusTimesGrid';

export const StatusTimesDesktop = ({ ticketEvents, isRowEditable, handleRowEdit, handleRefreshClick, refreshing }) => {
  const { isOnline } = useDataStore();
  const { values, errors } = Form.useFormContext();

  return (
    <Styled className={cn('status-times-desktop')}>
      <table data-testid={testId}>
        <thead>
          <tr data-testid={`${testId}-header-row`}>
            <th>
              <Translate stringId="status" data-testid="statusLabel" />
            </th>
            <th>
              <Translate stringId="time" data-testid="timeLabel" />
            </th>
            <th>
              {isOnline && (
                <div onClick={handleRefreshClick} data-testid={'refresh-button'} className="refresh">
                  <i className={`fa fa-refresh mr-2 ${refreshing === true ? 'fa-spin' : ''}`} />
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {ticketEvents?.map((event, idx) => {
            return (
              <tr
                key={event.eventType}
                data-testid={`${testId}-row-${idx}`}
                className={cn({ hasError: !!errors?.ticketEvents?.[event.eventType] })}
              >
                <td data-testid={`${testId}-row-${idx}-eventTypeTranslated-value`}>
                  {event.eventTypeTranslated || event.eventType}
                  <span className="error">*</span>
                </td>
                <td data-testid={`${testId}-row-${idx}-eventDateTime-value`}>
                  <Time date={values?.ticketEvents?.[event.eventType]?.eventDateTime || event.eventDateTime} />
                </td>
                <td align="center">
                  {isRowEditable(event) && (
                    <div onClick={() => handleRowEdit(event)}>
                      <i className="fa-light fa-pen-to-square" data-testid={`${testId}-row-${idx}-editButton`} />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styled>
  );
};
