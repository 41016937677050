import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Typography, Layout, Localization, Form, Core } from 'connex-cds';
import { useTicketContext } from '../../../../../TicketContext';
import { useCompanySetup } from '../../../../../MasterDataProvider';
import { filter, map } from 'lodash';

const { Timeline } = Core;
const { Translate } = Localization;

const Styled = styled(Layout.Container)`
  ${style}
`;
export const StatusTimeline = ({ data }) => {
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const mergedTicketEvents = { ...ticket.ticketEvents, ...values.ticketEvents };

  const ticketEvents = map(mergedTicketEvents, (value, key) => ({ eventType: key, ...value }));

  const filteredEvents = filter(ticketEvents, event => {
    switch (event?.eventType) {
      case 'PRINTED':
        if (!companySetup?.data?.hidePrintedEvent) return true;
        break;
      case 'LOADING_STARTED':
        if (!companySetup?.data?.hideLoadingStartedEvent) return true;
        break;
      case 'LOADING_COMPLETE':
        if (!companySetup?.data?.hideLoadingCompleteEvent) return true;
        break;
      case 'TO_JOB':
        if (!companySetup?.data?.hideToJobEvent) return true;
        break;
      case 'ARRIVE_JOB':
        if (!companySetup?.data?.hideArriveJobEvent) return true;
        break;
      case 'UNLOADING':
        if (!companySetup?.data?.hideUnloadingEvent) return true;
        break;
      case 'END_UNLOADING':
        if (!companySetup?.data?.hideEndUnloadingEvent) return true;
        break;
      case 'LEAVE_JOB':
        if (!companySetup?.data?.hideLeaveJobEvent) return true;
        break;
      case 'IN_YARD':
        if (!companySetup?.data?.hideInYardEvent) return true;
        break;
      case 'PUMPING_STARTED':
        if (!companySetup?.data?.hidePumpingStartedEvent) return true;
        break;
      case 'PUMPING_COMPLETE':
        if (!companySetup?.data?.hidePumpingCompleteEvent) return true;
        break;
      case 'READY_TO_PUMP':
        if (!companySetup?.data?.hideReadyToPumpEvent) return true;
        break;
      default:
        return false;
    }
  });

  if (ticket?.customData?.productDetails?.wcContactDateTime && !companySetup?.data?.hideFirstWaterCementContact) {
    const wCContact = {
      eventType: 'FIRST_WCCONTACT',
      eventDateTime: ticket?.customData?.productDetails?.wcContactDateTime,
    };
    filteredEvents.push(wCContact);
  }

  // We need to map our object to the requirements of the Timeline component.
  // Optionally, action can be added to zero or more items and, if present, will be rendered
  // to the right of the description.
  const items = React.useMemo(() => {
    return filteredEvents.map(event => ({
      isoDateString: event.eventDateTime,
      description: (
        <div>
          <div style={{ display: 'flex' }}>
            <div>
              <Translate stringId={event.eventType} />
            </div>
          </div>
        </div>
      ),
    }));
  }, [filteredEvents]);

  return (
    <Styled className={cn('simple-grid-story')}>
      <div>
        <Typography.Subtitle>
          <Translate stringId="status" data-testid="statusLabel" /> (
          <Localization.TimeZone date={items[0].isoDateString} />)
        </Typography.Subtitle>
      </div>

      <Timeline items={items} noData={<div>No Data</div>} desc />
    </Styled>
  );
};
