import { css } from 'styled-components';

export default css`
  padding: 5px;
  .header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .left {
      display: flex;
      //gap: 15px;
      align-items: center;
      flex: 1;
      button {
        margin-right: 15px;
      }
      h3 {
        margin-bottom: 0;
      }
    }
    .right {
      display: flex;
      //gap: 15px;
    }
  }

  &.setup-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    .setup-list {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .list-section {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .ant-drawer-body {
      padding-top: 0;
    }
  }

  .simple-setup-editor {
    padding-top: 24px;
    .actions {
      display: flex;
      //gap: 15px;
      & > * {
        &:first-child {
          flex: 1;
        }
        &:last-child {
          display: flex;
          & > * {
            &:not(:last-child) {
              margin-right: 15px;
            }
          }
          //gap: 15px;
        }
      }
    }
  }
`;
