import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import { camelCase, find, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  useCompanySetup,
  useCreateSetupItem,
  useProductTypeSetup,
  useUpdateSetupItem,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

const allCountries = require('../../countryList');

export const ProductEditor = ({ config }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const companySetup = useCompanySetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const productTypeSetup = useProductTypeSetup();

  const busy = React.useMemo(() => {
    return companySetup.isLoading || vehicleTypeSetup.isLoading || productTypeSetup.isLoading;
  }, [companySetup.isLoading, productTypeSetup.isLoading, vehicleTypeSetup.isLoading]);

  //TODO: This is just a bandaid, need to find cause of different formats
  const waterType = React.useMemo(() => {
    let waterProductType;
    if (Array.isArray(companySetup.data)) {
      waterProductType = companySetup.data?.[0]?.waterProductType;
    } else {
      waterProductType = companySetup.data?.waterProductType;
    }
    return waterProductType;
  }, [companySetup.data]);

  const create = useCreateSetupItem();
  const update = useUpdateSetupItem();

  const editMasterData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const saveMasterData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(() => {
    return saveMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveMasterData, values]);

  const handleEdit = React.useCallback(() => {
    return editMasterData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editMasterData, values]);

  const fieldComponents = React.useMemo(() => {
    const countries = companySetup?.data?.countries?.map?.(country => find(allCountries, { id: country })) || [];

    return config?.fields
      ?.filter(c => (companySetup?.data?.isMulticountry !== true ? c.path !== 'countries' : c))
      ?.reduce(
        (acc, field) => {
          const FieldComponent = Components[pascalCase(field.name || field.path)];
          FieldComponent.displayName = field.name || field.path;
          if (field.path.startsWith('mobileTicket.')) {
            acc.mobileTicket.push(<FieldComponent />);
          } else if (field.path === 'countries') {
            acc.general.push(<FieldComponent options={countries} busy={companySetup.isLoading} />);
          } else if (field.path === 'vehicleTypes') {
            acc.general.push(
              <FieldComponent options={vehicleTypeSetup.data || []} busy={vehicleTypeSetup.isLoading} />
            );
          } else {
            acc.general.push(<FieldComponent />);
          }
          return acc;
        },
        { general: [], mobileTicket: [], waterConfig: [] }
      );
  }, [
    Components,
    companySetup?.data,
    companySetup.isLoading,
    config?.fields,
    vehicleTypeSetup.data,
    vehicleTypeSetup.isLoading,
  ]);

  React.useEffect(() => {
    if (values?.type && productTypeSetup.isSuccess) {
      const defaultProductType = find(productTypeSetup.data, r => r.id === values?.type);
      setFieldValue('uomCode', values?.uomCode || defaultProductType?.uomCode?.id);
    }
  }, [productTypeSetup.data, productTypeSetup.isSuccess, setFieldValue, values?.type, values?.uomCode]);

  const tabConfig = React.useMemo(() => {
    const generalComponents =
      values.type === waterType?.id
        ? fieldComponents.general.filter(c => c?.type?.displayName !== 'isDriverSellable')
        : fieldComponents.general;

    const config = {
      tabs: [{ stringId: 'general', testId: 'general-tab', component: generalComponents }],
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };

    const waterFields = [
      'mobileTicket.isConcreteOnTruckFieldEnabled',
      'mobileTicket.isTimeFieldEnabled',
      'mobileTicket.isReasonFieldEnabled',
    ];

    const mobileTicketComponents =
      values.type === waterType?.id
        ? fieldComponents.mobileTicket.filter(c => waterFields.includes(c?.type?.displayName))
        : fieldComponents.mobileTicket;

    if (values?.isDriverSellable || values.type === waterType?.id) {
      config.tabs.push({
        stringId: 'mobileTicket',
        testId: 'mobileTicket-tab',
        component: mobileTicketComponents,
      });
    }

    return config;
  }, [fieldComponents.general, fieldComponents.mobileTicket, values?.isDriverSellable, values.type, waterType]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('product-editor', { tabbed: tabConfig?.tabs?.length > 1 })}>
        {tabConfig?.tabs?.length > 1 ? <Core.Tabs config={tabConfig} /> : tabConfig?.tabs?.[0]?.component}
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
