import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Localization, Typography, Form, Core } from 'connex-cds';
import { find } from 'lodash';
import { useTicketContext } from '../../../TicketContext';

const Styled = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .container {
    padding: 15px 25px;
    position: relative;
    overflow: visible;
    .has-error-icon {
      font-size: 1.5em;
      position: absolute;
      top: -5px;
      left: -5px;
      display: none;
    }
    &.hasError {
      border-color: var(--color-background-button-primary-dangerous);
      .has-error-icon {
        display: block;
        color: var(--color-background-button-primary-dangerous);
      }
    }
  }

  table {
    width: 100%;
    margin-left: 60px;
    td {
      width: 33%;
    }
    th {
      text-align: left;
    }
  }

  .content {
    h2,
    h3 {
      margin-bottom: 0;
      white-space: nowrap;
    }
    h3 {
      margin-top: -5px;
    }
    .second-row {
      display: flex;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
  }

  .didNotAdd {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
`;

export const CustomListComponent = props => {
  const { data } = props;

  const {
    deleteItem,
    resetForm,
    parentFormContext: { errors },
  } = Form.useFormContext();

  const { ticket } = useTicketContext();

  const handleDeleteClick = React.useCallback(
    item => {
      deleteItem(item);
      resetForm({ reinitialize: true });
    },
    [deleteItem, resetForm]
  );

  const handleRowClick = React.useCallback(
    row => {
      if (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length !== 0 ||
        !row.suggestedToDriver
      ) {
        props.onRowClick?.(row);
      }
    },
    [props, ticket?.ticketEvents]
  );

  const disabled = React.useCallback(
    item => {
      return (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length === 0 && item?.suggestedToDriver
      );
    },
    [ticket?.ticketEvents]
  );

  return (
    <Styled className={cn('custom-list-component')}>
      {data?.map?.((item, idx) => {
        if (!item?.isPrimary) {
          return (
            <Layout.Container
              onClick={() => handleRowClick(item)}
              className={cn({ hasError: !!errors?.lineItems?.[idx] })}
            >
              <i className="has-error-icon fa-sharp fa-solid fa-circle-exclamation" />
              <div className="content">
                <div className="description">
                  <Typography.H3 data-testid="product-value">{item.item?.description}</Typography.H3>
                </div>
                <div className="second-row">
                  <div className="left">
                    <div className="description-quantity">
                      {item?.driverDidNotAdd !== true && (
                        <Typography.H3 data-testid="product-quantity">
                          <Localization.Uom uom={item.quantity} />
                        </Typography.H3>
                      )}
                    </div>
                    {item?.driverDidNotAdd !== true ? (
                      <table>
                        <thead>
                          <tr>
                            {item?.item?.mobileTicket?.isReasonFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="reason" data-testid="reason-header" />
                              </th>
                            )}
                            {item?.item?.mobileTicket?.isConcreteOnTruckFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="onBoard" data-testid="concreteOnTruck-header" />
                              </th>
                            )}
                            {item?.item?.mobileTicket?.isTimeFieldEnabled === false ? (
                              <th style={{ display: 'none' }}></th>
                            ) : (
                              <th>
                                <Localization.Translate stringId="time" data-testid="time-header" />
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {item?.item?.mobileTicket?.isReasonFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="reason-cell">{item?.reason?.description}&nbsp;</td>
                            )}
                            {item?.item?.mobileTicket?.isConcreteOnTruckFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="concreteOnTruck-cell">
                                <Localization.Uom uom={item.concreteOnTruck} />
                              </td>
                            )}
                            {item?.item?.mobileTicket?.isTimeFieldEnabled === false ? (
                              <td style={{ display: 'none' }}></td>
                            ) : (
                              <td data-testid="time-cell">
                                <Localization.Time date={item.time} />
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <div className={'didNotAdd'}>
                        <Localization.Translate stringId="driverDidNotAdd" data-testid="driverDidNotAddText" />
                      </div>
                    )}
                  </div>
                  <div className="right">
                    <div className="deleteButton">
                      <Core.Button
                        onClick={e => {
                          handleDeleteClick(item);
                          e.stopPropagation();
                        }}
                        stringId="delete"
                        data-testid="delete-button"
                        type="primary"
                        danger
                        disabled={disabled(item)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Layout.Container>
          );
        }
        return null;
      })}
    </Styled>
  );
};
