import cn from 'classnames';
import { Core, Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { isEmpty } from 'lodash';

const allCountries = require('../../../countryList');

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const General = ({ busy, onSave }) => {
  const { Components, values, errors } = Form.useFormContext();

  return (
    <Styled className={cn('general')}>
      <Column flex={1}>
        <Container flex={1} style={{ padding: '15px' }}>
          <Row stretchContent>
            <Components.Multicountry />
          </Row>
          {values.isMulticountry && (
            <Row stretchContent>
              <Components.Countries options={allCountries} />
            </Row>
          )}
          <Row stretchContent>
            <Components.TimeZone />
          </Row>
        </Container>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.RefreshEnabled />
            <Components.PollingInterval />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.MaxLineItems />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Row className="driver-comment-config">
              <Components.DriverCommentEnabled />
              <Components.DriverCommentMaxCharacters />
            </Row>
          </Container>
        </Row>

        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Row className="customer-comment-config">
              <Components.CustomerCommentEnabled />
              <Components.CustomerCommentMaxCharacters />
            </Row>
          </Container>
        </Row>

        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.UnFinalizePassword />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.QrCodeEnabled />
          </Container>
        </Row>
        <div className="actions" style={{ padding: '15px' }}>
          <Core.Button
            loading={busy}
            type="primary"
            data-testid="save-button"
            stringId="save"
            onClick={onSave}
            disabled={!isEmpty(errors)}
          />
        </div>
      </Column>
    </Styled>
  );
};
