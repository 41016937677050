import cn from 'classnames';
import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  display: flex;
  gap: 20px;
  border: 1px solid var(--color-border-card-error);
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 10px;
  pre {
    margin-bottom: 0;
  }
`;

export const QrCodeTemplateErrors = ({ invalidReferences }) => {
  if (!invalidReferences?.length) return null;
  return (
    <Styled className={cn('qr-code-template-errors error')}>
      <div className="div">
        <b>
          <Localization.Translate stringId="invalidReferences" data-testid="invalid-references" />
        </b>
      </div>
      <pre>{invalidReferences?.join(', ')}</pre>
    </Styled>
  );
};
