import { get } from 'lodash';
import React from 'react';
import { useCompanySetup } from '../../MasterDataProvider';
import defaultQrCodeTemplate from '../defaultQrCodeTemplate';
import { usePreparedContext } from '../usePreparedContext';

export const referencedKeys = template => {
  const found = [];
  const rxp = /{([^}]+)}/g;
  let curMatch;

  while ((curMatch = rxp.exec(template))) {
    found.push(curMatch[1]);
  }

  return found;
};

export const useTemplate = () => {
  const companySetup = useCompanySetup();

  const emptyContext = usePreparedContext();

  const templateString = React.useMemo(() => {
    return companySetup.data?.qrCodeTemplate || defaultQrCodeTemplate;
  }, [companySetup.data?.qrCodeTemplate]);

  const invalidReferences = React.useMemo(() => {
    const validKeys = Object.keys(emptyContext);
    return referencedKeys(templateString).filter(f => !validKeys.includes(f));
  }, [emptyContext, templateString]);

  const isValid = React.useMemo(() => {
    return invalidReferences?.length === 0;
  }, [invalidReferences?.length]);

  const compile = React.useCallback(
    context => {
      return referencedKeys(templateString)?.reduce?.((acc, path) => {
        const pattern = `{${path}}`;
        const regex = new RegExp(pattern, 'g');

        acc = acc.replace(regex, get(context, path));
        return acc;
      }, templateString);
    },
    [templateString]
  );

  return { templateString, isValid, invalidReferences, compile };
};
