import { useEntity } from 'connex-cds';
import React from 'react';
import { matchPath, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { IFrameAuthenticated } from './views/apps/entity-ref';
import menuConfig from './views/apps/entity-ref/menu-config';
import MobileTicket from './views/apps/entity-ref/mobile-ticket/MobileTicket';
import GeneralSetup from './views/apps/entity-ref/mobile-ticket/setup/general/GeneralSetup';
import { DynamicSetup } from './views/apps/entity-ref/mobile-ticket/setup/master-data/dynamic-setup/DynamicSetup';
import { Setup } from './views/apps/entity-ref/mobile-ticket/setup/Setup';
import { TruckNumber } from './views/apps/entity-ref/mobile-ticket/truck-number/TruckNumber';

const InitialRoute = () => {
  const { entityRef } = useEntity();

  const defaultDestination = menuConfig[0];
  if (!entityRef) return null;
  return <Navigate to={`/${entityRef}${defaultDestination.path}`} replace />;
};

const RedirectToSelectedEntity = () => {
  const { entityRef } = useEntity();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!entityRef) return;

    // if the entityRef changes navigate to the same route with the new entityRef
    if (entityRef && !location.pathname.includes(entityRef)) {
      const matchWithEntityRef = matchPath(`/:entityRef/*`, location.pathname);

      if (matchWithEntityRef?.params?.entityRef) {
        const newPathname = matchWithEntityRef.pathname.replace(matchWithEntityRef.params.entityRef, entityRef);
        navigate(newPathname);
        return;
      }

      navigate(`/${entityRef}`);
    }
  }, [entityRef, location, navigate]);

  return (
    <IFrameAuthenticated>
      <Outlet />
    </IFrameAuthenticated>
  );
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<RedirectToSelectedEntity />}>
        <Route path=":entityRef/*" element={<IFrameAuthenticated />}>
          <Route path="" element={<InitialRoute />} exact />
          <Route path="setup/*">
            <Route path="" element={<Setup />} exact />
            <Route path="master-data/:typeId/*" element={<DynamicSetup />} />
            <Route path="general/:typeId/*" element={<GeneralSetup />} />
          </Route>
          <Route path="driver/*">
            <Route path="" exact element={<TruckNumber />} />
            <Route path=":truckNumber/*" element={<MobileTicket driverExperience />} />
          </Route>
          <Route path=":truckNumber">
            <Route path="mt/*" element={<MobileTicket />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
