import enUS from './en-US';
import de from './de';
import fr from './fr-FR';
import nl from './nl-NL';
import sv from './sv-SE';
import da from './da';
import no from './no-NO';

export default {
  'en-US': enUS,
  de,
  'fr-FR': fr,
  'nl-NL': nl,
  'sv-SE': sv,
  da,
  'no-NO': no,
};
