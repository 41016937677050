import { useParams } from 'react-router-dom';
import { useSetupConfig } from '../config';
//import config from '../config';

const GeneralSetup = () => {
  const { typeId } = useParams();
  const config = useSetupConfig();

  return config?.general?.[typeId]?.component;
};

export default GeneralSetup;
