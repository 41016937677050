import React from 'react';
import { Form } from 'connex-cds';
import styled from 'styled-components';

const Image = styled.img`
  position: absolute;
  top: 10px;

  -webkit-filter: var(--signature-image-invert);
  filter: var(--signature-image-invert);
`;

export const SignatureImage = () => {
  const { values } = Form.useFormContext();

  const signature = React.useMemo(() => {
    return values?.signature?.image;
  }, [values?.signature?.image]);

  return signature ? <Image src={signature} /> : null;
};
