import { find } from 'lodash';
import React from 'react';
import { useCompanySetup, useVehicleTypeSetup } from '../../MasterDataProvider';
import allCountries from '../countryList';
import company from './company';
import productTypes from './product-types';
import product from './products';
import reasonCodes from './reason-codes';
import standard from './standard';
import vehicleTypes from './vehicle-types';
import vehicle from './vehicles';
import driverComments from './driver-comments';
import dataRetention from './data-retentions';
import { Localization } from 'connex-cds';
import { categories, intervals } from '../custom-editors/data-retentions-editor/DataRetentionEditor';

export const useSetupConfig = () => {
  const [countries, setCountries] = React.useState();
  const [setupVehicleTypes, setSetupVehicleTypes] = React.useState();
  const [maxCharacters, setMaxCharacters] = React.useState();

  const companySetup = useCompanySetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  React.useEffect(() => {
    if (companySetup?.isSuccess) {
      setCountries(companySetup?.data?.countries?.map?.(country => find(allCountries, { id: country })) || []);
      setMaxCharacters(companySetup?.data?.driverCommentMaxCharacters);
    }
  }, [
    companySetup?.data?.countries,
    companySetup?.data?.driverCommentMaxCharacters,
    companySetup?.isSuccess,
    companySetup.success,
  ]);

  React.useEffect(() => {
    if (vehicleTypeSetup?.isSuccess) setSetupVehicleTypes(vehicleTypeSetup?.data);
  }, [vehicleTypeSetup?.data, vehicleTypeSetup?.isSuccess]);

  const { translateObjects } = Localization.useTranslateObjects();

  const translatedIntervals = React.useMemo(() => {
    return translateObjects(intervals, {
      getPath: 'id',
      setPath: 'label',
    });
  }, [translateObjects]);

  const translatedCategories = React.useMemo(() => {
    return translateObjects(categories, {
      getPath: 'id',
      setPath: 'label',
    });
  }, [translateObjects]);

  const config = React.useMemo(() => {
    return {
      general: {},
      masterData: {
        'reason-code': reasonCodes(countries, setupVehicleTypes),
        'unloading-method': standard(<i className="icon fa-thin fa-truck-ramp-box" />, countries, setupVehicleTypes),
        'driver-comment': driverComments(countries, setupVehicleTypes, maxCharacters),
        'product-type': productTypes(countries, setupVehicleTypes),
        product: product(countries, setupVehicleTypes),
        'entity-mt': company,
        vehicle: vehicle(countries, setupVehicleTypes),
        'vehicle-type': vehicleTypes,
        'data-retentions': dataRetention(translatedIntervals, translatedCategories),
      },
    };
  }, [countries, maxCharacters, setupVehicleTypes, translatedCategories, translatedIntervals]);
  return config;
};
